import React from 'react';
import { isMobile } from 'react-device-detect';

import Breadcrumb from '@/components/Breadcrumb';
import Button from '@/components/Button';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import RecommendationCard from '@/components/RecommandationCard';
import Title from '@/components/Title';

import { ProcessContext } from '..';

import Styles from './styles.module.scss';
import { TCartStep } from './interfaces';
import Spinner from '@/components/Spinner';
import Icon from '@/components/Icon';
import { TCustomerAnswerResponse } from '@/helpers/API/requests/interfaces/customer';

const CartStep: React.FC<TCartStep> = ({ prev, products, onSubmit }) => {
  const { recommendations } = React.useContext(ProcessContext);
  const { routines } = recommendations as TCustomerAnswerResponse;

  if (products) {
    const routinesFiltered = routines.filter((routine) => {
      return (
        routine.proposed_products.find(({ id }) => products.map((product) => product.id).includes(id)) !== undefined ||
        routine.sub_routines.filter((sub) => {
          return sub.proposed_products.find(({ id }) => products.map((product) => product.id).includes(id)) !== undefined;
        }).length > 0
      );
    });
    if (routinesFiltered.length === 0) {
      onSubmit();
    }

    return (
      <div className={Styles['cartStep']}>
        <Breadcrumb step={isMobile ? 3 : 4} />
        <div className={Styles['cartStep__back-link']}>
          <div className={Styles['cartStep__back-link--item']} onClick={prev}>
            <Icon icon='arrow-left' className={Styles['cartStep__back-icon']} />
            <LocaleText>form.layout.back</LocaleText>
          </div>
        </div>
        <Inner type='md' className={Styles['cartStep__heading']}>
          <Title className={Styles['cartStep__title']}>
            <LocaleText>page.cartStep.title</LocaleText>
          </Title>
          <Title className={Styles['cartStep__subtitle']}>
            <LocaleText>page.cartStep.subtitle</LocaleText>
          </Title>
        </Inner>

        <div className={Styles['cartStep__body']}>
          {routinesFiltered.map((routine, rIndex) => {
            const matchingProducts = [];
            const isProductSelected = (productId: number) => products.some((selectedProduct) => selectedProduct.id === productId);

            for (const product of routine.proposed_products) {
              if (isProductSelected(product.id)) {
                matchingProducts.push(product);
              }
            }

            for (const subRoutine of routine.sub_routines) {
              for (const product of subRoutine.proposed_products) {
                if (isProductSelected(product.id)) {
                  matchingProducts.push(product);
                }
              }
            }

            if (matchingProducts.length > 0) {
              return (
                <div key={routine.position} className={Styles.cartStep__routine__container}>
                  <div className={Styles.cartStep__routine__title}>
                    <span>{rIndex + 1}.</span>
                    {routine.title}
                  </div>
                  {matchingProducts.map((product) => (
                    <RecommendationCard key={`recommendationCard-${product.id}`} className={Styles.cartStep__routine__card} {...product} isActivable={false} />
                  ))}
                </div>
              );
            }

            return null;
          })}
        </div>
        <div className={Styles['cartStep__footer']}>
          <Inner type='md' className={Styles['cartStep__footer-inner']}>
            <p className={Styles['cartStep__footer-selected']}>
              {products.length} <LocaleText plural={products.length !== 1}>page.cartStep.selectedProduct</LocaleText>
            </p>
            <Button onClick={onSubmit} className={Styles['cartStep__button']}>
              <LocaleText>page.cartStep.submit</LocaleText>
            </Button>
          </Inner>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles['cartStep']}>
      <Breadcrumb step={isMobile ? 3 : 4} />
      <Spinner className={Styles['cartStep__loading']} />
    </div>
  );
};

export default CartStep;
