import React from 'react';

import Form from '@/components/Form';

import Layout from './Layout';

import { ECustomerGender } from '@/helpers/API/requests/interfaces/customer';

import { TPersonalizationStepForm } from './interfaces';
import LocaleText from '@/components/Intl/LocaleText';

const Sex: React.FC<TPersonalizationStepForm> = ({ formRef }) => (
    <Layout title='form.personalizationStep.sex.title'>
        {Object.values(ECustomerGender).map(value => (
            <Form.RadioButton
                key={value}
                name='gender'
                value={value}
                onChanged={() => {
                    formRef.current!.next();
                }}
            >
                <LocaleText>{'form.personalizationStep.radio.' + value}</LocaleText>   
            </Form.RadioButton>
        ))}
    </Layout>
);

export default Sex;