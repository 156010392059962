import React, { useCallback, useContext, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

import Breadcrumb from "@/components/Breadcrumb";
import Button from "@/components/Button";
import Inner from "@/components/Inner";
import LocaleText from "@/components/Intl/LocaleText";
import Routine from "@/components/Routine";
import NoRecommendation from "@/globals/images/no_recommendation.svg";

import Title from "@/components/Title";

import { ProcessContext } from "..";
import { LocaleContext } from "@/providers/LocaleProvider";

import Styles from "./styles.module.scss";
import { TProductInCart, TRecommendationStep } from "./interfaces";
import AbandonPopin from "@/components/PopinAbandon";

const RecommendationStep: React.FC<TRecommendationStep> = ({
  products,
  onSubmit,
}) => {
  const { recommendations, resetClock, goToHome } = useContext(ProcessContext);
  const localeContext = useContext(LocaleContext);

  const [currentStep, setCurrentStep] = useState(0);
  const [routinesLimit, setRoutinesLimit] = useState(4);
  const [routines, setRoutines] = useState<Array<TProductInCart | false>>([]);
  const [productList, setProductList] = useState(products ?? []);
  const [abandonPopin, setAbandonPopin] = useState<boolean>(false);

  const onCardSelect = useCallback(
    (id: number, active: boolean) => {
      const tempList = routines.slice();
      resetClock();
      if (active) {
        tempList.push({ id });
        setRoutines(tempList);
        // setCurrentStep(routineIndex + 1);
      } else {
        tempList.splice(
          tempList.findIndex((product) => product && product?.id === id),
          1,
          false
        );
        setRoutines(tempList);
      }
    },
    [resetClock, routines]
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    // TODO To be confirmed: never true because recommendations is an empty array when there is none
    let routines: Array<TProductInCart | false> = [];
    if (recommendations?.routines) {
      recommendations.routines.forEach((routine: any) => {
        const p = routine.proposed_products.find(({ id }: any) =>
          products?.includes(id)
        );
        routines.push(p ? p.id : false);
        routine.sub_routines.forEach((sub: any) => {
          const p = sub.proposed_products.find(({ id }: any) =>
            products?.includes(id)
          );
          routines.push(p ? p.id : false);
        });
      });
    }
    setRoutines(routines);
  }, [products, recommendations, goToHome, localeContext.locale]);

  useEffect(() => {
    setProductList(
      routines
        .slice()
        .filter((p) => !!p)
        .map((p) => p as TProductInCart)
    );
  }, [routines]);

  let indexRoutine = 0;

  return recommendations?.routines &&
    Array.isArray(recommendations.routines) &&
    recommendations.routines.length > 0 ? (
    <div className={Styles["recommendationStep"]}>
      <Breadcrumb step={isMobile ? 3 : 4} />
      <Inner type="md" className={Styles["recommendationStep__heading"]}>
        <Title className={Styles["recommendationStep__title"]}>
          <LocaleText>page.recommendationStep.title</LocaleText>
        </Title>
        <Title className={Styles["recommendationStep__subtitle"]}>
          <LocaleText>page.recommendationStep.subtitle</LocaleText>
        </Title>
      </Inner>

      <div className={Styles["recommendationStep__body"]}>
        {recommendations.routines
          .sort((a: any, b: any) => a.position - b.position)
          .slice(0, routinesLimit)
          .map((routine: any, rIndex: number) => {
            let el = (
              <Routine
                key={"recommendationRoutine-" + rIndex}
                index={indexRoutine}
                rIndex={rIndex}
                routine={routine}
                active={routines?.[indexRoutine]}
                recommendationRoutines={routines}
                isCurrentStep={currentStep === rIndex ? true : false}
                onStepChange={setCurrentStep}
                onCardSelect={onCardSelect}
              />
            );
            indexRoutine = indexRoutine + 1 + routine.sub_routines.length;
            return el;
          })}
        {recommendations.routines.length > routinesLimit ? (
          <p className={Styles["recommendationStep__more"]}>
            <Button
              size="medium"
              outline={true}
              onClick={() => setRoutinesLimit(1000)}
              className={Styles["recommendationStep__more--button"]}
            >
              <LocaleText>page.recommendationStep.more</LocaleText>
            </Button>
          </p>
        ) : (
          ""
        )}
      </div>
      <div className={Styles["recommendationStep__footer"]}>
        <Inner type="md" className={Styles["recommendationStep__submit"]}>
          <Button
            fullwidth={true}
            outline={true}
            onClick={() => onSubmit(productList)}
            className={Styles["recommendationStep__button"]}
          >
            <LocaleText>page.recommendationStep.submit</LocaleText>
          </Button>
        </Inner>
        <div className={Styles["recommendationStep__abandon"]}>
          <p
            onClick={() => {
              setAbandonPopin(true);
            }}
          >
            <LocaleText>page.recommendationStep.abandon</LocaleText>
          </p>
        </div>
      </div>
      <AbandonPopin
        active={abandonPopin}
        onContinue={() => {
          setAbandonPopin(false);
        }}
      />
    </div>
  ) : (
    <div className={Styles["recommendationStep"]}>
      <Breadcrumb step={isMobile ? 3 : 4} />
      <div className={Styles.recommendationStep__noRecommendation}>
        <div
          className={
            Styles.recommendationStep__noRecommendation__titleContainer
          }
        >
          <Title className={Styles["recommendationStep__title"]} type={2}>
            <LocaleText>
              page.recommendationStep.noRecommendation.title
            </LocaleText>
          </Title>
          <span className={Styles["recommendationStep__subtitle"]}>
            <LocaleText>
              page.recommendationStep.noRecommendation.subtitle
            </LocaleText>
          </span>
        </div>
        <div className={Styles.recommendationStep__noRecommendation__content}>
          <img src={NoRecommendation} alt="" />
          <span
            className={Styles.recommendationStep__noRecommendation__message}
          >
            <LocaleText>
              page.recommendationStep.noRecommendation.message
            </LocaleText>
          </span>
          <button
            className={Styles.recommendationStep__noRecommendation__backHome}
            onClick={goToHome}
          >
            <LocaleText>
              page.recommendationStep.noRecommendation.backHome
            </LocaleText>
          </button>
        </div>
      </div>
    </div>
  );
};

export default RecommendationStep;
