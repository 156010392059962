import React from 'react';

import { TInnerProps } from './interfaces';
import Styles from './styles.module.scss';

const Inner : React.FC<TInnerProps> = ({
    className = '',
    type = 'lg',
    children,
    onClick
}) => (
    <div 
        className={`${Styles['inner']} ${type ? (Styles['inner--' + type]) : ''} ${className ? className : ''}`}
        onClick={onClick}
    >
        {children}
        </div>
);

export default Inner;