import React from 'react';
import { isMobile } from 'react-device-detect';

import Breadcrumb from '@/components/Breadcrumb';
import Button from '@/components/Button';
import ImagePolygon from '@/components/ImagePolygon';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import AbandonPopin from '@/components/PopinAbandon';
import PopinDiagnosis from '@/components/PopinDiagnosis';
import Spinner from '@/components/Spinner';
import Title from '@/components/Title';

import { LocaleContext } from '@/providers/LocaleProvider';

import { ProcessContext } from '..';

import { TDiagnosisStep } from './interfaces';
import Styles from './styles.module.scss';
import { TCustomerSelfieResponse } from '@/helpers/API/requests/interfaces/customer';

const getRatingSettings = function (score: number, scoreType: string) {
  return {
    color: `page.diagnosisStep.color.${scoreType}`,
    label: `page.diagnosisStep.score.${scoreType}`,
  };
};

const DiagnosisStep: React.FC<TDiagnosisStep> = ({ onSubmit }) => {
  const { getTranslation } = React.useContext(LocaleContext);

  const {
    picture: { current: picture },
    diagnosis,
  } = React.useContext(ProcessContext);
  const { results } = diagnosis as TCustomerSelfieResponse;

  const [abandonPopin, setAbandonPopin] = React.useState<boolean>(false);

  return results ? (
    <div className={Styles['diagnosisStep']}>
      <div className={Styles['diagnosisStep__main']}>
        <Breadcrumb step={isMobile ? 3 : 4} />
        <Inner type='md' className={Styles['diagnosisStep__heading']}>
          <Title className={Styles['diagnosisStep__title']}>
            <LocaleText>page.diagnosisStep.title</LocaleText>
          </Title>
        </Inner>

        <div className={Styles['diagnosisStep__body']}>
          <div className={Styles['diagnosisStep__cards']}>
            {results.map((card: any, cIndex: number) => {
              if (card.name !== '') {
                return (
                  <div key={'diagnosisCard-' + cIndex} className={Styles['diagnosisCardContainer']}>
                    <div className={Styles['diagnosisCard']}>
                      <div className={Styles['diagnosisCard__heading']}>
                        <p className={Styles['diagnosisCard__title']}>{card.name}</p>
                        <div
                          className={`${Styles['diagnosisCard__rating']} ${
                            Styles['diagnosisCard__rating--' + getTranslation(getRatingSettings(card.score, card.score_type)?.color)]
                          }`}
                        >
                          <div className={Styles['diagnosisCard__rating-percentage']}>
                            <p className={Styles['diagnosisCard__rating-percentage-number']}>{card.score}</p>

                            <svg viewBox='-1 -1 38 38' className={Styles['diagnosisCard__rating-percentage-circle']}>
                              <path
                                className={Styles['diagnosisCard__rating-percentage-circle-bg']}
                                d='M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831'
                              />
                              <path
                                className={Styles['diagnosisCard__rating-percentage-circle-fg']}
                                strokeDasharray={`${card.score ? (card.score > 5 ? card.score - 5 : 0) : 0}, 100`}
                                d='M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831'
                              />
                            </svg>
                          </div>
                          <p className={Styles['diagnosisCard__rating-label']}>{card.front_name}</p>
                        </div>
                      </div>
                      <div className={Styles['diagnosisCard__visual']}>
                        <ImagePolygon image={picture ?? ''} polygons={[card]} className={Styles['diagnosisCard__visual-image']} />
                      </div>

                      <div className={Styles['diagnosisCard__description']}>
                        <p>{card.description}</p>
                      </div>
                    </div>
                  </div>
                );
              } else if (card.slug === 'age') {
                return (
                  <div key={'diagnosisCard-' + cIndex} className={Styles['diagnosisCardContainer']}>
                    <div className={Styles['diagnosisCard']}>
                      <div className={Styles['diagnosisCard__heading-age']}>
                        <p className={Styles['diagnosisCard__title']}>
                          <LocaleText>page.diagnosisStep.skin_age</LocaleText>
                        </p>
                      </div>
                      <div className={Styles['diagnosisCard__visual']}>
                        <div className={Styles['diagnosisCard__visual-image-age']}>
                          {card.score} <LocaleText>page.diagnosisStep.years</LocaleText>
                        </div>
                      </div>

                      <div className={Styles['diagnosisCard__description']}>
                        <LocaleText>page.diagnosisStep.skin_age_description</LocaleText>
                      </div>
                    </div>
                  </div>
                );
              } else return null;
            })}
          </div>
        </div>
      </div>
      <div className={Styles['diagnosisStep__footer']}>
        <Inner type='md' className={Styles['diagnosisStep__submit']}>
          <Button fullwidth={true} onClick={onSubmit} className={Styles['diagnosisStep__button']}>
            <LocaleText>page.diagnosisStep.submit</LocaleText>
          </Button>
        </Inner>
        <div className={Styles['diagnosisStep__abandon']}>
          <p
            onClick={() => {
              setAbandonPopin(true);
            }}
          >
            <LocaleText>page.pictureStep.diagnosisStep.stop</LocaleText>
          </p>
        </div>
      </div>
      <AbandonPopin
        active={abandonPopin}
        onContinue={() => {
          setAbandonPopin(false);
        }}
      />
    </div>
  ) : (
    <div className={Styles['diagnosisStep']}>
      <Breadcrumb step={isMobile ? 3 : 4} />
      <Spinner className={Styles['diagnosisStep__loading']} />
      <PopinDiagnosis active={true} />
    </div>
  );
};

export default DiagnosisStep;
