export const toRGBA = (color: string, a: number): string => {
    const rgb = [0, 0, 0];

    if (color.startsWith('#')) {
        for (let i = 0; i < rgb.length; ++i) {
            rgb[i] = parseInt(color.substring(1 + 2 * i, 1 + 2 * (i + 1)), 16);
        }
    }
    else {
        let offset = 4;

        if (color.charAt(3) === 'a') {
            ++offset;
        }

        for (let i = 0; i < color.length; ++i) {
            const start = offset;

            offset = color.indexOf(',', offset);
            if (offset === -1) {
                offset = color.indexOf(')');
            }
            rgb[i] = parseInt(color.substring(start, offset).trim());
            ++offset;
        }
    }

    return `rgba(${rgb[0]}, ${rgb[1]}, ${rgb[2]}, ${a})`;
}


function hexToRgb(hex: string) {
    const num = parseInt(hex.replace("#", ""), 16);
    return {
        R: num >> 16,
        G: num >> 8 & 0x00FF,
        B: num & 0x0000FF
    }
}


function clamp(value: number) {
    return value < 0 ? 0 : value > 255 ? 255 : value;
}

function rgbToHex(R: number, G: number, B: number) {
    return "#" + ((1 << 24) + (R << 16) + (G << 8) + B).toString(16).slice(1);
}

export function adjustColorBrightness(color: string, percent: number) {

    const { R, G, B } = hexToRgb(color);


    const amt = Math.round(2.55 * percent);


    const adjustedR = clamp(R + amt);
    const adjustedG = clamp(G + amt);
    const adjustedB = clamp(B + amt);


    return rgbToHex(adjustedR, adjustedG, adjustedB);
}
