import React from 'react';

import useDidUpdate from '@/hooks/useDidUpdate';

import { FormContext } from '../provider';

import Styles from './styles.module.scss';

import validation from '@/helpers/validation';
import { EValidation, TValidation } from '@/helpers/validation/interfaces';

import { TFormOverrideCallback, TFormOverrideCallbacks, TFormValueBase } from '../interfaces';

import { TCheckboxProps } from './interfaces';
import Icon from '@/components/Icon';

const Checkbox: React.FC<TCheckboxProps> = ({
    name,
    className = '',
    id,
    value,
    defaultChecked,
    checked,
    onChange,
    onChanged,
    required,
    validate
}) => {
    const formContext = React.useContext(FormContext);
    const itemId = React.useId();

    const [_value, setValue] = React.useState(defaultChecked ?? checked ?? ((formContext.datas[name] as TFormValueBase[] | undefined)?.includes(value)) ?? false);
    const [error, setError] = React.useState<string | null>(null);

    const _onChange = React.useCallback((__value: boolean) => {
        const changeValue = onChange?.(__value, value);

        setValue((changeValue === undefined ? __value : changeValue));
    }, [onChange, value]);

    useDidUpdate(() => {
        _onChange(checked ?? false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    React.useEffect(() => {
        if (Array.isArray(formContext.datas[name])) {
            const index = (formContext.datas[name] as TFormValueBase[]).indexOf(value);

            if (_value) {
                if (index === -1) {
                    (formContext.datas[name] as TFormValueBase[]).push(value);
                }
            }
            else if (index !== -1) {
                (formContext.datas[name] as TFormValueBase[]).splice(index, 1);
            }
        }
        else {
            formContext.datas[name] = _value ? [value] : [];
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, _value]);

    React.useEffect(() => () => {
        delete formContext.datas[name];
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    React.useEffect(() => {
        if (!formContext.override[name]) {
            formContext.override[name] = {};
        }

        (formContext.override[name] as TFormOverrideCallbacks)[itemId] = ((data) => _onChange(Array.isArray(data) && (data as TFormValueBase[]).includes(value as TFormValueBase))) as TFormOverrideCallback;
        return () => {
            delete (formContext.override[name] as TFormOverrideCallbacks)[itemId];
            if (Object.keys(formContext.override[name]).length === 0) {
                delete formContext.override[name];
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_onChange, name]);

    React.useEffect(() => {
        const _validate: TValidation =  JSON.parse(JSON.stringify(validate ?? {}));

        if (required) {
            _validate[EValidation.Required] = {
                enabled: true,
                message: _validate[EValidation.Required]?.message ?? 'this field is required !'
            }
        }

        const isNotEmpty = Object.keys(_validate).length > 0;

        if (isNotEmpty) {
            formContext.validate[name] = (__value) => {
                const _error = validation(_validate, __value);

                setError(_error);
                return _error === null;
            };
        }

        return () => {
            if (isNotEmpty) {
                delete formContext.validate[name];
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, required, validate]);

    useDidUpdate(() => {
        onChanged?.(_value, value);
    }, [_value]);

    return (
        <div className={Styles['checkbox']}>
            <input
                id={id}
                className={`${Styles['checkbox__input']} ${className ? className : ''}`}
                name={name}
                type='checkbox'
                value={value.toString()}
                required={required}
                checked={_value}
                onChange={({ target }) => _onChange(target.checked)}
            />
            <Icon className={Styles['checkbox__icon']} icon='check' />
            {error && <p>{error}</p>}
        </div> 
    );
}

export default Checkbox;