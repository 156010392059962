import React from 'react';

import 'slick-carousel/slick/slick.css';

import Icon from '@/components/Icon';

import Styles from './styles.module.scss';
import { TRecommendationCard } from './interfaces';
import LocaleText from '@/components/Intl/LocaleText';

const RecommendationCard: React.FC<TRecommendationCard> = ({
  id,
  name,
  description,
  brand,
  className,
  isVisible = true,
  image_url,
  isActive = false,
  isActivable = true,
  onSelect = () => {},
}) => {
  const [initialSelect, setInitialSelect] = React.useState(true);

  return (
    <div className={`${Styles['recommendationCardContainer']}${className ? ` ${className}` : ''}`} data-id={id}>
      <div
        className={`${Styles['recommendationCard']}${isActive ? ` ${Styles['recommendationCard--active']}` : ''}${
          isVisible ? ` ${Styles['recommendationCard--visible']}` : ''
        }`}
      >
        <div className={Styles['recommendationCard__body']}>
          <div className={Styles['recommendationCard__visual']}>
            <img src={image_url} className={Styles['recommendationCard__visual-image']} alt='card_visual' />
          </div>
          <div className={Styles['recommendationCard__content']}>
            <p className={Styles['recommendationCard__title']}>{name}</p>
            <p className={Styles['recommendationCard__brand']}>{brand?.name}</p>
            <p dangerouslySetInnerHTML={{ __html: description }} className={Styles['recommendationCard__description']}></p>
          </div>
        </div>
        {isActivable ? (
          <div
            className={Styles['recommendationCard__footer']}
            onClick={() => {
              onSelect(id, name, initialSelect, !isActive, undefined, image_url);
              setInitialSelect(false);
            }}
          >
            {isActive ? (
              <>
                <p className={Styles['recommendationCard__footer-active']}>
                  <LocaleText>page.recommendationStep.footer.active </LocaleText>
                </p>
                <Icon icon='cross' />
              </>
            ) : (
              <p className={Styles['recommendationCard__footer-default']}>
                <LocaleText>page.recommendationStep.footer.default </LocaleText>
              </p>
            )}
          </div>
        ) : undefined}
      </div>
    </div>
  );
};

export default RecommendationCard;
