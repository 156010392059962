import React, { useEffect, useState } from 'react';

import Inner from '@/components/Inner';
import RecommendationCard from '@/components/RecommandationCard';
import Styles from './styles.module.scss';

import { TSubRoutine } from './interfaces';

const Routine: React.FC<TSubRoutine> = ({ index, rIndex, parentIndex, routine, isCurrentStep, active, onCardSelect }) => {
  const [activeCards, setActiveCards] = useState<number[]>(
    routine.proposed_products.reduce((acc, product, index) => {
      if (active && product.id === active.id) {
        acc.push(index);
      }
      return acc;
    }, [] as number[])
  );

  const onProductSelect = (id: number, name: string, isInitial: boolean, active: boolean, routineIndex: number, pIndex: number, image_url?: string) => {
    setActiveCards((prevActiveCards) => {
      if (active) {
        return [...prevActiveCards, pIndex];
      } else {
        return prevActiveCards.filter((index) => index !== pIndex);
      }
    });

    onCardSelect(id, active, routineIndex);
  };

  useEffect(() => {
    const activeIndices = routine.proposed_products.reduce((acc, product, index) => {
      if (active && product.id === active.id) {
        acc.push(index);
      }
      return acc;
    }, [] as number[]);
    setActiveCards(activeIndices);
  }, [active, routine.proposed_products]);

  return (
    <div
      key={'recommendationRoutine-' + parentIndex + '-sub-' + rIndex}
      className={`${Styles['recommendationRoutine']} ${isCurrentStep ? Styles['recommendationRoutine--active'] : ''}`}
    >
      <Inner className={Styles['recommendationRoutine__heading']}>
        <div className={Styles['recommendationRoutine__title']}>
          <span>
            {parentIndex + 1}.{rIndex + 1}
          </span>
          {routine.title}
        </div>
      </Inner>
      <div className={Styles['recommendationRoutine__body']}>
        <Inner type='full'>
          <div className={Styles['recommendationRoutine__slider']}>
            {routine?.proposed_products?.map((product: any, pIndex: number) => (
              <RecommendationCard
                key={'recommendationCard-' + pIndex}
                {...product}
                isVisible={isCurrentStep}
                isActive={activeCards.includes(pIndex)}
                onSelect={(id, name, isInitial, active, i, image_url) => onProductSelect(id, name, isInitial, active, index, pIndex, image_url)}
              />
            ))}
          </div>
        </Inner>
      </div>
    </div>
  );
};

export default Routine;
