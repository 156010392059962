import React from 'react';

import FormMultiStep, { TForm, TFormMultiStepRef } from '@/components/FormMultiStep';

import {
    SexForm,
    AgeForm,
    SkinForm,
    SensibilityForm,
    PathologyForm,
    HealthTargetForm,
    EmailForm
} from '@/forms/PersonalizationStep';

import { ProcessContext } from '@/screens/Auth/ProcessAI';

import { CampaignContext } from '@/providers/AuthProvider/campaignInfoProvider';

import { TPersonalizationStep } from './interfaces';
import Styles from './styles.module.scss';

const steps = [
    { name: 'sex', Component: SexForm },
    { name: 'age', Component: AgeForm },
    { name: 'skin', Component: SkinForm },
    { name: 'sensibility', Component: SensibilityForm },
    { name: 'pathology', Component: PathologyForm },
    { name: 'healthTarget', Component: HealthTargetForm },
    { name: 'email', Component: EmailForm },
];

const PersonalizationStep: React.FC<TPersonalizationStep> = ({ onSubmit, onStepChange }) => {
    const { isLaboratory, hideSensitiveSkin, availablePathologies } = React.useContext(CampaignContext);
    const { diagnosis } = React.useContext(ProcessContext);
    const formMultiStep = React.useRef<TFormMultiStepRef>(null);
    const timeoutId = React.useRef<NodeJS.Timeout>();
    const diagnosisRef = React.useRef<any>(diagnosis);

    const debounceRequest = React.useCallback((form: TForm) => {
        if (!timeoutId.current) {
            if (diagnosisRef.current === null) {
                timeoutId.current = setTimeout(() => {
                    timeoutId.current = undefined;
                    debounceRequest(form);
                }, 200);
            }
            else {
                onSubmit(form, diagnosisRef.current);
            }
        }
    }, [onSubmit]);

    React.useEffect(() => {
        if (diagnosis !== diagnosisRef.current) {
            diagnosisRef.current = diagnosis;
        }
    }, [diagnosis]);

    let stepCount = steps.length;
    if(isLaboratory) {
        if(hideSensitiveSkin === true) {
            stepCount--;
        }
        if(availablePathologies.length == 0) {
            stepCount--;
        }
    }

    return (
        <div className={Styles['personnalisationStep__root']}>
            <FormMultiStep
                ref={formMultiStep}
                totalSteps={stepCount}
                onStepChange={onStepChange}
                delay={300}
                onSubmit={debounceRequest}
            >
                {steps.map(({ name, Component }) => {
                    if(isLaboratory) {
                        if(name == 'sensibility' && hideSensitiveSkin === true) {
                            return;
                        } else if (name == 'pathology' && availablePathologies.length == 0) {
                            return;
                        }
                    }
                    return (
                        <FormMultiStep.Step key={name} name={name}>
                            <Component formRef={formMultiStep} />
                        </FormMultiStep.Step>
                    )
                })}
        </FormMultiStep>
    </div>
    );
};

export default PersonalizationStep;