import React from 'react';
import { Routes, Route } from "react-router-dom";

import ERoutes from './routes';

/**
 * Screens
 */
import Animator from "@/screens/Auth/Animator";
import AnimatorNewCampaign from "@/screens/Auth/AnimatorNewCampaign";
import ProcessAI from '@/screens/Auth/ProcessAI';
import NotFound from '@/screens/NotFound';

const AuthRouter: React.FC = () => (
    <Routes>
        <Route path={ERoutes.Animator} element={<Animator />} />
        <Route path={ERoutes.AnimatorNewCampaign} element={<AnimatorNewCampaign />} />
        <Route path={ERoutes.ProcessAI} element={<ProcessAI />} />
        <Route path='*' element={<NotFound redirect={ERoutes.ProcessAI} />} />
    </Routes>
);

export default AuthRouter;