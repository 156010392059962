import React from 'react';
import { isMobile } from 'react-device-detect';
import LocaleText from '../Intl/LocaleText';

import { TBreadcrumbProps } from './interfaces';
import Styles from './styles.module.scss';

const steps = isMobile
  ? [
      { slug: 'photo', title: <LocaleText>global.topBar.photo</LocaleText> },
      { slug: 'personnalisation', title: <LocaleText>global.topBar.personalization</LocaleText> },
      { slug: 'bilan', title: <LocaleText>global.topBar.review</LocaleText> },
    ]
  : [
      { slug: 'photo', title: <LocaleText>global.topBar.photo</LocaleText> },
      { slug: 'analyse', title: <LocaleText>global.topBar.analyse</LocaleText> },
      { slug: 'personnalisation', title: <LocaleText>global.topBar.personalization</LocaleText> },
      { slug: 'bilan', title: <LocaleText>global.topBar.review</LocaleText> },
    ];

const Breadcrumb: React.FC<TBreadcrumbProps> = ({ step = 1 }) => {
  return (
    <div className={`${Styles['breadcrumb']}`}>
      {steps.map((s, i) => (
        <div key={'BreadcrumbStep-' + (i + 1)} className={`${Styles['breadcrumb__item']} ${step === i + 1 ? Styles['is-active'] : ''}`}>
          <span className={`${Styles['breadcrumb__item-index']}`}>{i + 1}.</span>
          <span className={`${Styles['breadcrumb__item-title']}`}>{s.title}</span>
        </div>
      ))}
    </div>
  );
};

export default Breadcrumb;
