import React from 'react';

import LocaleText from '@/components/Intl/LocaleText';
import Styles from './styles.module.scss';
import FormStyles from '../../components/FormMultiStep/styles.module.scss';

import { TPersonalizationStepFormLayout } from './interfaces';

const Layout: React.FC<TPersonalizationStepFormLayout> = ({ title, children }) => (
    <div className={`${Styles['personnalisationStep']} ${FormStyles['multiStepForm__step']}`}>
        <h1 className={`title ${Styles['personnalisationStep__title']}`}>
            <LocaleText>{title}</LocaleText>
        </h1>
        <div className={Styles['personnalisationStep__content']}>
            {children}
        </div>
    </div>
);

export default Layout;