import React from 'react';

import LocaleText from '@/components/Intl/LocaleText';

import { TErrorBoundaryPage } from './interfaces';
import Styles from './styles.module.scss';

const ErrorPage: React.FC<TErrorBoundaryPage> = ({ error, errorInfo, clearError }) => process.env.NODE_ENV === 'production' ? (
    <div className={Styles['error__wrapper']}>
        <div className={Styles['error__title']}>
            <LocaleText isHtml={true}>global.error.title</LocaleText>
        </div>
        <p className={Styles['error__subtitle']}>
            <LocaleText>global.error.subtitle</LocaleText>
        </p>
        {error && (
            <div className={Styles['error__description']}>
                {error.toString()}
            </div>
        )}
        <>{console.log(errorInfo?.componentStack)}</>
        <p className={Styles.error_backHome} onClick={clearError}>
            <LocaleText>global.error.backHome</LocaleText>
        </p>
    </div>
) : (
    <div>
        <h2>Something went wrong.</h2>
        <details style={{ whiteSpace: 'pre-wrap' }}>
        {error && error.toString()}
        <br />
        {errorInfo?.componentStack}
        </details>
    </div>
);

export default ErrorPage;