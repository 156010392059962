import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import AbandonPopin from '@/components/PopinAbandon';
import Button from '@/components/Button';
import Breadcrumb from '@/components/Breadcrumb';
import Icon from '@/components/Icon';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import MultiStep, { TMultiStepRef } from '@/components/MultiStep';
import Popin from '@/components/Popin';
import RoundProgress from '@/components/RoundProgress';

import { ProcessContext } from '../../screens/Auth/ProcessAI';

import Styles from './styles.module.scss';
import { TPictureStep } from './interfaces';
import { LocaleContext } from '@/providers/LocaleProvider';
import Form from '@/components/Form';
import PopinError from '@/components/PopinError';
import { CampaignContext } from '@/providers/AuthProvider/campaignInfoProvider';
import PopinPdf from '@/components/PopinPdf';
import { EUserRole } from '@/providers/AuthProvider/interfaces';
import { AuthContext } from '@/providers/AuthProvider';
import { pdfjs } from 'react-pdf';

const analysisItemsTotal = 8;
const analysisItemsTotalRendered = 6;
const analisisItemsTransitionDelayMs = 1000;
const analisisItemsTransitionDurationMs = 500;
const analisisItemsDurationMs = analisisItemsTransitionDelayMs + analisisItemsTransitionDurationMs;
const analisisItemsTotalDurationMs = analisisItemsDurationMs * analysisItemsTotalRendered;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PictureNonMobile: React.FC<TPictureStep> = ({ onSubmit, onError }) => {
  const { resetClock, sendPicture } = useContext(ProcessContext);
  const { getTranslation } = useContext(LocaleContext);
  let { document, isLaboratory, labName } = useContext(CampaignContext);
  /* The data portion of a base64 string representing a pdf always begins with JVB.
   * This condition adds the metadata at the beginning of the string if it is missing */
  if (document.startsWith('JVB')) document = `data:application/pdf;base64,${document}`;

  const pictureBodyRef = useRef<TMultiStepRef>(null);
  const videoInputRef = useRef<any>(null);
  const canvasRef = useRef<any>(null);

  const [videoPermissionGiven, setVideoPermissionGiven] = useState<boolean>(false);
  const [stepTransition, setStepTransition] = useState<boolean>(false);
  const [isTransitionPrev, setIsTransitionPrev] = React.useState<boolean>(false);
  const [flash, setFlash] = useState<boolean>(false);
  const [imageData, setImageData] = useState<string | null>(null);
  const [countdown, setCountdown] = useState<number>(-1);
  const [analysisItem, setAnalysisItem] = useState<number>(0);
  const [isPicTaken, setIsPicTaken] = useState<boolean>(false);
  const [abandonPopin, setAbandonPopin] = useState<boolean>(false);
  const [legalNoticePopin, setLegalNoticePopin] = useState<boolean>(true);
  const [progressIsRunning, startProgress] = React.useState<boolean>(false);
  const [progress, setProgress] = React.useState<number>(0);
  const [consentCgu, setConsentCgu] = React.useState<boolean>(false);
  const [consentLegalNotice, setConsentLegalNotice] = React.useState<boolean>(false);
  const [errorPopin, setErrorPopin] = useState<string | null>(null);
  const [errorCameraNotAllowedPopin, setErrorCameraNotAllowedPopin] = useState<boolean | null>(null);
  const [errorCameraNotFoundPopin, setErrorCameraNotFoundPopin] = useState<boolean | null>(null);
  const [imageFile, setImageFile] = useState<File | null>(null);
  const [uploadImage, setUploadImage] = useState<boolean>(false);
  const [pdfPopinCGU, setPdfPopinCGU] = useState<boolean>(false);
  const [pdfPopinMentionsLegales, setPdfPopinMentionsLegales] = useState<boolean>(false);
  const [pdfPopinPolitiqueConfidentialite, setPdfPopinPolitiqueConfidentialite] = useState<boolean>(false);
  const [pdfPopinCGULaboratory, setPdfPopinCGULaboratory] = useState(false);
  const [consentCguLaboratory, setConsentCguLaboratory] = useState(false);

  const auth = useContext(AuthContext);

  const { user } = auth;

  let isAdmin = user?.roles.includes(EUserRole.ADMIN);

  const { primaryColor } = useContext(CampaignContext);

  const progressDelay = React.useMemo(() => Math.round(Math.random() * 500) + 500, []);
  const analysisItemsRendered = React.useMemo(() => {
    const list = Array(analysisItemsTotal)
      .fill(0)
      .map((_, i) => i);

    while (list.length > analysisItemsTotalRendered) {
      const removeStep = Math.trunc(Math.random() * analysisItemsTotalRendered);

      if (removeStep !== analysisItemsTotalRendered) {
        const removeIndex = list.indexOf(removeStep);

        if (removeIndex !== -1) {
          list.splice(removeIndex, 1);
        }
      }
    }
    return list.map((item) => `page.pictureStep.analysisStep.list.${item}`);
  }, []);

  const takePicture = useCallback(() => {
    const video = videoInputRef.current;
    const canvas = canvasRef.current;

    pictureBodyRef.current!.next();
    if (video && canvas) {
      setTimeout(() => {
        setCountdown(3);
      }, 500);
    }
  }, []);

  const analysisStep = useCallback(
    (index: number) => {
      startProgress(true);
      setTimeout(() => {
        setAnalysisItem(index + 1);
        if (index < analysisItemsRendered.length - 1) {
          analysisStep(index + 1);
        }
      }, analisisItemsDurationMs);
    },
    [analysisItemsRendered.length]
  );

  useEffect(() => {
    if (progressIsRunning) {
      const timeId = setTimeout(() => {
        const _progress = Math.min(progress + Math.round(100 / ~~(analisisItemsTotalDurationMs / progressDelay)), 100);

        setProgress(_progress);
        if (_progress === 100) {
          startProgress(false);
        }
      }, progressDelay);

      return () => {
        clearTimeout(timeId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progressIsRunning, progress]);

  useEffect(() => {
    if (!progressIsRunning && progress === 100 && analysisItem === analysisItemsRendered.length) {
      setTimeout(() => {
        pictureBodyRef.current!.next();
      }, analisisItemsTransitionDelayMs);
    }
  }, [analysisItem, analysisItemsRendered.length, progress, progressIsRunning]);

  useEffect(() => {
    if (navigator.mediaDevices?.getUserMedia && videoInputRef.current !== null) {
      navigator.mediaDevices
        .getUserMedia({
          video: true,
        })
        .then((stream) => {
          videoInputRef.current.srcObject = stream;
          videoInputRef.current.play();
          setVideoPermissionGiven(true);
        })
        .catch((error) => {
          //Firefox || Chrome
          //permission denied in browser
          if ('NotAllowedError' === error.name || 'PermissionDeniedError' === error.name) {
            setErrorCameraNotAllowedPopin(true);
          } else if ('NotFoundError' === error.name) {
            setErrorCameraNotFoundPopin(true);
          } else {
            setErrorPopin(error.message);
            console.log(error.name);
            console.error('Something went wrong !', error);
          }
        });
    } else {
      setErrorCameraNotFoundPopin(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const nextCountdown = countdown - 1;

    if (nextCountdown >= 0) {
      const timeId = setTimeout(() => {
        setCountdown(nextCountdown);
      }, 1000);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [countdown]);

  useEffect(() => {
    if (!countdown && !uploadImage) {
      const video = videoInputRef.current;
      const canvas = canvasRef.current;

      if (!video || !canvas) return;

      const context = canvas.getContext('2d');

      // Obtenir les dimensions de la vidéo
      const videoWidth = video.videoWidth;
      const videoHeight = video.videoHeight;

      // Réglez les dimensions du canvas pour correspondre à la résolution de la vidéo
      canvas.width = videoWidth;
      canvas.height = videoHeight;

      if (context) {
        context.save();
        context.scale(-1, 1);
        context.drawImage(video, -videoWidth, 0, videoWidth, videoHeight);
        context.restore();
      }

      const image_data_url = canvas.toDataURL('image/jpeg');

      setImageData(image_data_url);
      setIsPicTaken(true);
      setFlash(true);
    }
  }, [countdown]);

  const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageFile(e.target.files[0]);
    }
  };

  const onUpload = () => {
    // Access the selected file
    const file = imageFile;
    if (!file) {
      console.log('No file selected');
      return;
    }

    const imageUrl = URL.createObjectURL(file);

    const img = new Image();
    img.src = imageUrl;
    const canvas = canvasRef.current;

    img.onload = () => {
      const context = canvas.getContext('2d');

      if (!context || !canvas) return;

      const newWidth = 640;
      const newHeight = 640;

      canvas.width = newWidth;
      canvas.height = newHeight;

      context.drawImage(img, 0, 0, newWidth, newHeight);

      const base64Image = canvas.toDataURL('image/jpeg');
      setImageData(base64Image);
      setIsPicTaken(true);

      setUploadImage(true);
    };
  };

  useEffect(() => {
    if (flash) {
      const timeId = setTimeout(() => {
        setFlash(false);
      }, 80);

      return () => {
        clearTimeout(timeId);
      };
    }
  }, [flash]);

  return (
    <div className={Styles['pictureStep']}>
      <Popin showClose active={Boolean(errorPopin)} onClickUnderlay={onError} onClose={onError}>
        <div className={Styles['pictureStep__popin--error']}>
          <div>
            <LocaleText>global.error.subtitle</LocaleText>
          </div>
          <p>{errorPopin}</p>
          <span className={Styles['popin__close__link']} onClick={onError}>
            <LocaleText>global.error.backHome</LocaleText>
          </span>
        </div>
      </Popin>

      <PopinError
        active={Boolean(errorCameraNotFoundPopin)}
        onClose={onError}
        title={'global.popinErrorCameraNotFound.title'}
        description={'global.popinErrorCameraNotFound.description'}
        backHome={'global.popinErrorCameraNotFound.backHome'}
      />

      <PopinError
        active={Boolean(errorCameraNotAllowedPopin)}
        onClose={onError}
        title={'global.popinErrorCameraNotAllowed.title'}
        description={'global.popinErrorCameraNotAllowed.description'}
        backHome={'global.popinErrorCameraNotAllowed.backHome'}
      />

      <Popin active={Boolean(legalNoticePopin)} onClickUnderlay={() => null} onClose={onError}>
        <div>
          <p
            className={Styles['pictureStep__legal-notice-description']}
            dangerouslySetInnerHTML={{ __html: getTranslation('page.pictureStep.legalNotice.description') }}
          />
          <div className={Styles['pictureStep__legal-notice-consent']}>
            <div className={Styles['pictureStep__legal-notice-consent-item']}>
              <Form.Checkbox
                value={consentCgu}
                name='customer_cgu_consent'
                required
                onChange={(value) => {
                  setConsentCgu(value ? true : false);
                }}
              />
              <div>
                <LocaleText>page.pictureStep.legalNotice.readAndAccept</LocaleText>
                <a href='#' onClick={() => setPdfPopinCGU(true)}>
                  <LocaleText>page.pictureStep.legalNotice.cgu.name</LocaleText>
                </a>
                <LocaleText>page.pictureStep.legalNotice.skinAnalysia</LocaleText>
              </div>
            </div>
            <div className={Styles['pictureStep__legal-notice-consent-item']}>
              <Form.Checkbox
                value={consentLegalNotice}
                name='customer_consent'
                required
                onChange={(value) => {
                  setConsentLegalNotice(value ? true : false);
                }}
              />
              <div>
                <LocaleText>page.pictureStep.legalNotice.readAndAccept</LocaleText>
                <a href='#' onClick={() => setPdfPopinMentionsLegales(true)}>
                  <LocaleText>page.pictureStep.legalNotice.legalNotice.name</LocaleText>
                </a>
                <LocaleText>page.pictureStep.legalNotice.and</LocaleText>
                <a href='#' onClick={() => setPdfPopinPolitiqueConfidentialite(true)}>
                  <LocaleText>page.pictureStep.legalNotice.privacyPolicy</LocaleText>
                </a>
                <LocaleText>page.pictureStep.legalNotice.skinAnalysia</LocaleText>
              </div>
            </div>
            {isLaboratory ? (
              <div className={Styles['pictureStep__legal-notice-consent-item']}>
                <Form.Checkbox
                  value={consentCguLaboratory}
                  name='customer_cgu_lab_consent'
                  required
                  onChange={(value) => {
                    setConsentCguLaboratory(value ? true : false);
                  }}
                />
                <div>
                  <LocaleText>page.pictureStep.legalNotice.readAndAccept</LocaleText>
                  <a href='#' onClick={() => setPdfPopinCGULaboratory(true)}>
                    <LocaleText>page.pictureStep.legalNotice.cgu.name</LocaleText>
                  </a>
                  <LocaleText>page.pictureStep.legalNotice.company</LocaleText>
                  {labName}
                </div>
              </div>
            ) : null}
          </div>

          <div className={Styles['pictureStep__legal-notice-buttons']}>
            <Button disabled={!consentCgu || !consentLegalNotice || (isLaboratory && !consentCguLaboratory)} onClick={() => setLegalNoticePopin(false)}>
              <LocaleText>page.pictureStep.legalNotice.accept</LocaleText>
            </Button>
            <Button
              className={Styles['pictureStep__legal-notice-buttons--link']}
              onClick={() => {
                videoInputRef.current.srcObject?.getTracks().forEach((track: any) => track.stop());
                videoInputRef.current.srcObject = null;
                onError();
              }}
            >
              <LocaleText>page.pictureStep.legalNotice.decline</LocaleText>
            </Button>
          </div>
        </div>
      </Popin>

      <PopinPdf active={Boolean(pdfPopinCGU)} onClose={() => setPdfPopinCGU(false)} document={'cgu_skinanalysia.pdf'} />
      <PopinPdf active={Boolean(pdfPopinMentionsLegales)} onClose={() => setPdfPopinMentionsLegales(false)} document={'mentions_legales_skinanalysia.pdf'} />
      <PopinPdf
        active={Boolean(pdfPopinPolitiqueConfidentialite)}
        onClose={() => setPdfPopinPolitiqueConfidentialite(false)}
        document={'politique_confidentialite_skinanalysia.pdf'}
      />
      <PopinPdf active={pdfPopinCGULaboratory} onClose={() => setPdfPopinCGULaboratory(false)} document={document} />

      <Breadcrumb step={1} />
      <div className={Styles['pictureStep__container']}>
        <Inner>
          <div className={`${Styles['pictureStep__video']} ${isPicTaken ? Styles['is-picture'] : ''}`}>
            <div className={`${Styles['pictureStep__video-flash']} ${flash ? Styles['is-active'] : ''}`}></div>
            <div className={`${Styles['pictureStep__video-countdown']} ${countdown && countdown > 0 ? Styles['is-active'] : ''}`}>
              <svg className={`${Styles['pictureStep__video-countdown-text']}`} viewBox='-6 -6 12 12'>
                <text x='4.5' y='-3.8' fontSize='3' fill='#FFF'>
                  {countdown}
                </text>
              </svg>
            </div>
            {analysisItem && analysisItem > 0 ? (
              <div
                className={`${Styles['pictureStep__video-scan']} ${
                  analysisItem && analysisItem > 0 && analysisItem < analysisItemsRendered.length ? Styles['is-active'] : ''
                }`}
              ></div>
            ) : (
              ''
            )}
            <div className={Styles['pictureStep__video-mask']}>
              <Icon icon='mask' />
            </div>
            <video ref={videoInputRef} className={Styles['pictureStep__video-input']} autoPlay playsInline />
            <img src={imageData ?? undefined} className={Styles['pictureStep__video-image']} alt='picture_shot' />
            <canvas ref={canvasRef} className={Styles['pictureStep__video-canvas']}></canvas>
          </div>
        </Inner>

        <Inner className={`${Styles['pictureStep__body']} ${stepTransition ? Styles['is-transition'] : ''} ${isTransitionPrev ? Styles['is-prev'] : ''}`}>
          <MultiStep
            ref={pictureBodyRef}
            delay={500}
            onStepChange={resetClock}
            onPrevStep={() => {
              setIsTransitionPrev(true);
            }}
            onTransitionStart={() => {
              setTimeout(() => {
                setStepTransition(true);
              }, 50);
            }}
            onTransitionEnded={() => {
              setIsTransitionPrev(false);
              setStepTransition(false);
            }}
            onSubmitLastStep={() => {
              if (imageData) {
                onSubmit(imageData);
              }
            }}
          >
            <MultiStep.Step name={'initStep'}>
              <div className={`${Styles['pictureStep__step']}`}>
                <div className={Styles['pictureStep__step-content']}>
                  <div className={Styles['pictureStep__step-title']}>
                    <LocaleText isHtml={true}>page.pictureStep.initStep.title</LocaleText>
                  </div>
                  <p className={Styles['pictureStep__step-description']}>
                    <LocaleText>page.pictureStep.initStep.description</LocaleText>
                  </p>
                </div>
                <div className={Styles['pictureStep__step-footerCTA']}>
                  <div className={Styles['pictureStep__step-footerCTAButton']}>
                    <Button onClick={takePicture} disabled={!videoPermissionGiven}>
                      <LocaleText>page.pictureStep.initStep.cta</LocaleText>
                    </Button>
                  </div>
                  {isAdmin ? (
                    <div>
                      <input type='file' accept='image/jpeg, image/png' onChange={onFileChange} />
                      <button onClick={onUpload}>Uploader</button>
                    </div>
                  ) : null}
                </div>
              </div>
            </MultiStep.Step>
            <MultiStep.Step name={'photoStep'}>
              <div className={`${Styles['pictureStep__step']} ${Styles['pictureStep__step--centered']}`}>
                <div className={Styles['pictureStep__step-content']}>
                  <div className={Styles['pictureStep__step-title']}>
                    {!isPicTaken ? (
                      <LocaleText isHtml={true}>page.pictureStep.photoStep.ready</LocaleText>
                    ) : (
                      <LocaleText>page.pictureStep.photoStep.title</LocaleText>
                    )}
                  </div>
                  <div className={`${Styles['pictureStep__step-hiddenContent']} ${isPicTaken ? Styles['is-active'] : ''}`}>
                    <p className={Styles['pictureStep__step-description']}>
                      <LocaleText>page.pictureStep.photoStep.description</LocaleText>
                    </p>
                  </div>
                </div>
                <div className={`${Styles['pictureStep__step-hiddenContent']} ${isPicTaken ? Styles['is-active'] : ''}`}>
                  <div className={Styles['pictureStep__step-footerCTA-alt']}>
                    <Button
                      className={Styles['pictureStep__step-footerCTAButton']}
                      onClick={() => {
                        pictureBodyRef.current!.next();
                        analysisStep(0);
                        videoInputRef.current.srcObject.getTracks().forEach((track: any) => track.stop());
                        videoInputRef.current.srcObject = null;
                        if (imageData) {
                          sendPicture(imageData);
                        }
                      }}
                    >
                      <LocaleText>page.pictureStep.photoStep.cta</LocaleText>
                    </Button>
                    <div className={Styles['pictureStep__step-footer']}>
                      <p
                        onClick={() => {
                          pictureBodyRef.current?.prev();
                          setIsPicTaken(false);
                        }}
                      >
                        <LocaleText>page.pictureStep.photoStep.restart</LocaleText>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </MultiStep.Step>
            <MultiStep.Step name={'analysisStep'}>
              <div className={`${Styles['pictureStep__step']}`}>
                <div className={Styles['pictureStep__step-content']}>
                  <p className={Styles['pictureStep__step-title']}>
                    <LocaleText>page.pictureStep.analysisStep.title</LocaleText>
                  </p>
                  {progress === 100 && !progressIsRunning ? (
                    <Icon className={Styles.pictureStep__analysis__check} icon='check' />
                  ) : (
                    <RoundProgress
                      className={Styles.pictureStep__analysis__progress}
                      progress={progress}
                      foregroundColor={primaryColor}
                      transitionDelay={progressDelay}
                    >
                      {Math.round(progress)}%
                    </RoundProgress>
                  )}
                  <div className={Styles['pictureStep__analysis']}>
                    <ul
                      className={Styles['pictureStep__analysis__container']}
                      style={{
                        transitionDuration: `${analisisItemsTransitionDurationMs}ms`,
                        right: `${Math.min(analysisItem, analysisItemsRendered.length - 1) * 100}%`,
                      }}
                    >
                      {analysisItemsRendered.map((analysisItemRendered) => (
                        <li key={analysisItemRendered} className={Styles['pictureStep__analysis__container--item']}>
                          <LocaleText>{analysisItemRendered}</LocaleText>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </MultiStep.Step>
            <MultiStep.Step name={'diagnosisStep'}>
              <div className={`${Styles['pictureStep__step']}`}>
                <div className={Styles['pictureStep__step-content']}>
                  <p className={Styles['pictureStep__step-title']}>
                    <Icon icon='check' />
                    <LocaleText>page.pictureStep.diagnosisStep.title</LocaleText>
                  </p>
                  <p className={Styles['pictureStep__step-description']}>
                    <LocaleText>page.pictureStep.diagnosisStep.description</LocaleText>
                  </p>
                </div>
                <div className={Styles['pictureStep__step-footerCTA-alt']}>
                  <Button
                    className={Styles['pictureStep__step-footerCTAButton']}
                    onClick={() => {
                      pictureBodyRef.current!.next();
                    }}
                  >
                    <LocaleText>page.pictureStep.submit</LocaleText>
                  </Button>
                  <div className={Styles['pictureStep__step-footer']}>
                    <p
                      onClick={() => {
                        setAbandonPopin(true);
                      }}
                    >
                      <LocaleText>page.pictureStep.diagnosisStep.stop</LocaleText>
                    </p>
                  </div>
                </div>
                <AbandonPopin
                  title={'page.pictureStep.abandon'}
                  active={abandonPopin}
                  onContinue={() => {
                    setAbandonPopin(false);
                  }}
                />
              </div>
            </MultiStep.Step>
          </MultiStep>
        </Inner>
      </div>
    </div>
  );
};

export default PictureNonMobile;
