import React, { useContext, useMemo } from 'react';

import defaultLogo from '@/globals/images/Logo_SVG.svg';
import HomeImage from '@/globals/images/horatio_home_visual.png';

import Button from '@/components/Button';
import Inner from '@/components/Inner';
import Header from '@/components/Header';
import LocaleText from '@/components/Intl/LocaleText';
import Title from '@/components/Title';

import { shouldRefresh } from '@/helpers/refresh';

import Styles from './styles.module.scss';
import { THomeStep } from './interfaces';
import { useGenerateCampaign } from '@/hooks/useGenerateCampaign';
import { LaboratoryContext } from '@/providers/AuthProvider/labInfoProvider';

const isTablet = window.innerWidth >= 768;

const HomeStep: React.FC<THomeStep> = ({ onSubmit, hideLogout }) => {
  /* TODO There are 2 API calls to /token/generate-campaign when there is a campaign token in the URL.
     1 from here, 1 from the CampaignProvider. To be cleaned up */
  const { campaignData, isLoading } = useGenerateCampaign();
  const { laboratory: labPreviewData, labLoading, token: labToken } = useContext(LaboratoryContext);

  const laboratoryData = useMemo(() => {
    if (campaignData) {
      const {
        laboratory: { title, subtitle, logo, description, image },
      } = campaignData;

      return { title, subtitle, logo: logo ? `data:image/;base64,${logo}` : null, description, image: image ? `data:image/;base64,${image}` : null };
    }

    if (labPreviewData) {
      const { title, subtitle, logo, description, image } = labPreviewData;

      return { title, subtitle, logo: logo ? `data:image/;base64,${logo}` : null, description, image: image ? `data:image/;base64,${image}` : null };
    }

    return { title: '', subtitle: '', description: '', logo: '', image: '' };
  }, [campaignData, labPreviewData]);

  const handleSubmit = useMemo(() => {
    //Makes the button "Commencer" inactive in lab preview while maintaining its color. Using the disabled prop would make it grey.
    if (labToken === '') return onSubmit;
  }, [labToken, onSubmit]);

  React.useEffect(() => {
    if (shouldRefresh()) {
      window.location.reload();
    }
  }, []);

  if (isLoading || labLoading) {
    return <div>Chargement...</div>;
  } else {
    return (
      <div className={Styles['homeStep']}>
        <div>
          <Header hideDisconnect={hideLogout} laboratoryDataLogo={laboratoryData.logo || defaultLogo} />
          <Inner type='very-full'>
            <div className={Styles['homeStep__visual']}>
              <img className={Styles['homeStep__visual-image']} src={laboratoryData.image || HomeImage} alt='Home' />
            </div>
          </Inner>
          <Inner type='full' className={Styles['homeStep__content']}>
            <div>
              <Title type={2} className={Styles['homeStep__title']}>
                {laboratoryData.title || <LocaleText>page.homeStep.title</LocaleText>}
              </Title>

              <p className={Styles['homeStep__subtitle']}>{laboratoryData.subtitle || <LocaleText>page.homeStep.subtitle</LocaleText>}</p>
            </div>
            <div className={Styles['homeStep__description']}>
              {laboratoryData.description ||
                (isTablet ? <LocaleText isHtml>page.homeStep.descriptionTablet </LocaleText> : <LocaleText isHtml>page.homeStep.description </LocaleText>)}
            </div>
          </Inner>
        </div>
        <div className={Styles['homeStep__footer']}>
          <Button onClick={handleSubmit} className={Styles['homeStep__button']}>
            <LocaleText>page.homeStep.submit</LocaleText>
          </Button>
        </div>
      </div>
    );
  }
};

export default HomeStep;
