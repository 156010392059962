import React from 'react';
import Styles from './styles.module.scss';
import LocaleText from '@/components/Intl/LocaleText';
import Button from '@/components/Button';
import API from '@/helpers/API';
import { useNavigate } from 'react-router-dom';
import ERoutes from '@/routers/AuthRouter/routes';
import { AuthContext } from '@/providers/AuthProvider';
import jwtDecode from 'jwt-decode';
import { TUser } from '@/providers/AuthProvider/interfaces';
import Popin from '@/components/Popin';
import settingsLogo from '@/globals/images/settingsLogo.svg';
import locationLogo from '@/globals/images/locationLogo.svg';
import privacyLogo from '@/globals/images/privacyLogo.svg';
import safariLogo from '@/globals/images/safariLogo.svg';
import { EInputType } from '@/components/Form/Input';
import Form from '@/components/Form';
import HomeStep from '@/screens/Auth/ProcessAI/HomeStep';

type TPostLocationRes = {
  token: string;
};

type TPostLocationError = {
  error: string;
  message: string;
};

enum EGeoLocationError {
  noError = 0,
  userDenied = 1,
  browserNotSupported = 2,
  OsNotAllowedIOS = 3,
  OsNotAllowedAndroid = 4,
  codePharmacy = 5,
}

const g_geoErrorMessages: Record<number, string> = {
  [EGeoLocationError.browserNotSupported]: 'browser_not_supported',
  [EGeoLocationError.OsNotAllowedIOS]: 'os_not_allowed_ios',
  [EGeoLocationError.OsNotAllowedAndroid]: 'os_not_allowed_android',
  [EGeoLocationError.userDenied]: 'user_denied',
  [EGeoLocationError.codePharmacy]: 'code_pharmacy',
};

const Pharma: React.FC = () => {
  const navigate = useNavigate();
  const authContext = React.useContext(AuthContext);
  const [errorNoPharmacyAround, setErrorNoPharmacyAround] = React.useState<TPostLocationError | null>(null);
  const [error, setError] = React.useState<TPostLocationError | null>(null);
  const [geolocationDisable, setGeolocationDisable] = React.useState(EGeoLocationError.noError);

  const triggerPositionPermission = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;

          if (lat && lon) {
            // TODO CALL API + REDIRECT
            API.post<TPostLocationRes>('/pharmacy/token/generate', {
              latitude: lat,
              longitude: lon,
            })
              .then(({ data: { token } }) => {
                const { username, roles } = jwtDecode<TUser>(token);
                authContext.login(token, { username, roles });
                navigate(ERoutes.ProcessAI, {
                  state: {
                    step: 2,
                  },
                });
              })
              .catch((e) => {
                setErrorNoPharmacyAround(e.response.data);
              });
          }
        },
        (err) => {
          console.log(navigator.userAgent);
          const isIOS = /ios|iphone|mac|ipad/.test(navigator.userAgent.toLowerCase());
          console.warn(`ERREUR (${err.code}): ${err.message}`);
          setGeolocationDisable(isIOS ? EGeoLocationError.OsNotAllowedIOS : EGeoLocationError.OsNotAllowedAndroid);
        }
      );
    } else {
      setGeolocationDisable(EGeoLocationError.browserNotSupported);
    }
  };

  return (
    <div className={Styles['homeStep']}>
      {/* <Header hideDisconnect /> */}

      <Popin active={Boolean(errorNoPharmacyAround)} onClose={() => setErrorNoPharmacyAround(null)} onClickUnderlay={() => setErrorNoPharmacyAround(null)}>
        <p>
          <LocaleText>global.error.subtitle</LocaleText>
        </p>
        <p className={Styles['popin__error__desc']}>{errorNoPharmacyAround && <LocaleText>{errorNoPharmacyAround!.error}</LocaleText>}</p>
        <span className={Styles['popin__close__link']} onClick={() => setErrorNoPharmacyAround(null)}>
          fermer
        </span>
      </Popin>

      <Popin
        active={geolocationDisable !== EGeoLocationError.noError}
        onClose={() => setGeolocationDisable(EGeoLocationError.noError)}
        onClickUnderlay={() => setGeolocationDisable(EGeoLocationError.noError)}
      >
        <p className={Styles['popin__error__geo--hook']}>OH OH !</p>
        <div className={Styles['popin__error__geo']}>
          {g_geoErrorMessages[geolocationDisable] === g_geoErrorMessages[EGeoLocationError.OsNotAllowedIOS] ? (
            <>
              <LocaleText isHtml={true}>{`geo.error.${g_geoErrorMessages[EGeoLocationError.OsNotAllowedAndroid]}`}</LocaleText>
              <p className={Styles['popin__code__instruction']}>
                <LocaleText>{`geo.error.${g_geoErrorMessages[EGeoLocationError.codePharmacy]}`}</LocaleText>
              </p>
              <Form
                onSubmit={(form) => {
                  API.post<TPostLocationRes>('/pharmacy/token/generate-from-code', {
                    code: form.code,
                  })
                    .then(({ data: { token } }) => {
                      const { username, roles } = jwtDecode<TUser>(token);
                      authContext.login(token, { username, roles });
                      navigate(ERoutes.ProcessAI, {
                        state: {
                          step: 2,
                        },
                      });
                    })
                    .catch((e) => {
                      setError(e.response.data);
                    });
                }}
              >
                <Form.Input name='code' focus={true} type={EInputType.NUMBER} placeholder={'ex: 12345'} required />
                <p className={Styles['popin__error__code']}>{error && <LocaleText>{error!.error}</LocaleText>}</p>
                <Button type='submit'>Valider</Button>
              </Form>
            </>
          ) : (
            <LocaleText
              params={{ settingsLogo, locationLogo, privacyLogo, safariLogo }}
              isHtml={true}
            >{`geo.error.${g_geoErrorMessages[geolocationDisable]}`}</LocaleText>
          )}
        </div>
        <span className={Styles['popin__close__link']} onClick={() => setGeolocationDisable(EGeoLocationError.noError)}>
          fermer
        </span>
      </Popin>

      <HomeStep onSubmit={triggerPositionPermission} hideLogout={true} />

      <div className={Styles['homeStep__footer']}>
        <a href='https://preprod-react.horatio.sooyoos.org/mentions_legales_skinanalysia.pdf' target='_blank' rel='noreferrer'>
          Mentions légales
        </a>
      </div>
    </div>
  );
};

export default Pharma;
