import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';

import Button from '@/components/Button';
import Breadcrumb from '@/components/Breadcrumb';
import LocaleText from '@/components/Intl/LocaleText';
import Popin from '@/components/Popin';

import { ProcessContext } from '../../screens/Auth/ProcessAI';

import Styles from './styles.module.scss';
import { TPictureStep } from './interfaces';
import { LocaleContext } from '@/providers/LocaleProvider';
import Form from '@/components/Form';
import { CampaignContext } from '@/providers/AuthProvider/campaignInfoProvider';
import PopinPdf from '@/components/PopinPdf';
import { pdfjs } from 'react-pdf';
import { provideBase64Image } from '@/helpers/liqa';
import { ELocale } from '@/providers/LocaleProvider/interfaces';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PictureMobile: React.FC<TPictureStep> = ({ onSubmit, onError }) => {
  const { sendPicture } = useContext(ProcessContext);
  const { getTranslation, locale } = useContext(LocaleContext);
  let { document, isLaboratory, labName } = useContext(CampaignContext);
  /* The data portion of a base64 string representing a pdf always begins with JVB.
   * This condition adds the metadata at the beginning of the string if it is missing */
  if (document.startsWith('JVB')) document = `data:application/pdf;base64,${document}`;

  // TODO Implement type based on LIQA doc
  const liqaRef = useRef<any>();

  const [legalNoticePopin, setLegalNoticePopin] = useState<boolean>(true);
  const [consentLegalNotice, setConsentLegalNotice] = React.useState<boolean>(false);
  const [pdfPopinCGU, setPdfPopinCGU] = useState<boolean>(false);
  const [consentCgu, setConsentCgu] = React.useState<boolean>(false);
  const [pdfPopinMentionsLegales, setPdfPopinMentionsLegales] = useState<boolean>(false);
  const [pdfPopinPolitiqueConfidentialite, setPdfPopinPolitiqueConfidentialite] = useState<boolean>(false);
  const [pdfPopinCGULaboratory, setPdfPopinCGULaboratory] = useState(false);
  const [consentCguLaboratory, setConsentCguLaboratory] = useState(false);

  // TODO Implement type based on LIQA doc
  const handleImageCapture = useCallback(
    async (event: any) => {
      const image = await provideBase64Image(event);
      sendPicture(image);
      onSubmit(image as string);
    },
    [onSubmit, sendPicture]
  );

  useEffect(() => {
    if (liqaRef.current) {
      const reference = liqaRef.current;
      reference.addEventListener('capture', handleImageCapture);

      return () => reference.removeEventListener('capture', handleImageCapture);
    }
  }, [handleImageCapture]);

  return (
    <div className={Styles['pictureStep']}>
      <Popin active={Boolean(legalNoticePopin)} onClickUnderlay={() => null} onClose={onError}>
        <div>
          <p dangerouslySetInnerHTML={{ __html: getTranslation('page.pictureStep.legalNotice.description') }} />
          <div className={Styles['pictureStep__legal-notice-consent']}>
            <div className={Styles['pictureStep__legal-notice-consent-item']}>
              <Form.Checkbox
                value={consentCgu}
                name='customer_cgu_consent'
                required
                onChange={(value) => {
                  setConsentCgu(value ? true : false);
                }}
              />
              <div>
                <LocaleText>page.pictureStep.legalNotice.readAndAccept</LocaleText>
                <a href='#' onClick={() => setPdfPopinCGU(true)}>
                  <LocaleText>page.pictureStep.legalNotice.cgu.name</LocaleText>
                </a>
                <LocaleText>page.pictureStep.legalNotice.skinAnalysia</LocaleText>
              </div>
            </div>
            <div className={Styles['pictureStep__legal-notice-consent-item']}>
              <Form.Checkbox
                value={consentLegalNotice}
                name='customer_consent'
                required
                onChange={(value) => {
                  setConsentLegalNotice(value ? true : false);
                }}
              />
              <div>
                <LocaleText>page.pictureStep.legalNotice.readAndAccept</LocaleText>
                <a href='#' onClick={() => setPdfPopinMentionsLegales(true)}>
                  <LocaleText>page.pictureStep.legalNotice.legalNotice.name</LocaleText>
                </a>
                <LocaleText>page.pictureStep.legalNotice.and</LocaleText>
                <a href='#' onClick={() => setPdfPopinPolitiqueConfidentialite(true)}>
                  <LocaleText>page.pictureStep.legalNotice.privacyPolicy</LocaleText>
                </a>
                <LocaleText>page.pictureStep.legalNotice.skinAnalysia</LocaleText>
              </div>
            </div>
            {isLaboratory ? (
              <div className={Styles['pictureStep__legal-notice-consent-item']}>
                <Form.Checkbox
                  value={consentCguLaboratory}
                  name='customer_cgu_lab_consent'
                  required
                  onChange={(value) => {
                    setConsentCguLaboratory(value ? true : false);
                  }}
                />
                <div>
                  <LocaleText>page.pictureStep.legalNotice.readAndAccept</LocaleText>
                  <a href='#' onClick={() => setPdfPopinCGULaboratory(true)}>
                    <LocaleText>page.pictureStep.legalNotice.cgu.name</LocaleText>
                  </a>
                  <LocaleText>page.pictureStep.legalNotice.company</LocaleText>
                  {labName}
                </div>
              </div>
            ) : null}
          </div>

          <div className={Styles['pictureStep__legal-notice-buttons']}>
            <Button disabled={!consentCgu || !consentLegalNotice || (isLaboratory && !consentCguLaboratory)} onClick={() => setLegalNoticePopin(false)}>
              <LocaleText>page.pictureStep.legalNotice.accept</LocaleText>
            </Button>
            <Button
              className={Styles['pictureStep__legal-notice-buttons--link']}
              onClick={() => {
                onError();
              }}
            >
              <LocaleText>page.pictureStep.legalNotice.decline</LocaleText>
            </Button>
          </div>
        </div>
      </Popin>

      <PopinPdf active={Boolean(pdfPopinCGU)} onClose={() => setPdfPopinCGU(false)} document={'cgu_skinanalysia.pdf'} />
      <PopinPdf active={Boolean(pdfPopinMentionsLegales)} onClose={() => setPdfPopinMentionsLegales(false)} document={'mentions_legales_skinanalysia.pdf'} />
      <PopinPdf
        active={Boolean(pdfPopinPolitiqueConfidentialite)}
        onClose={() => setPdfPopinPolitiqueConfidentialite(false)}
        document={'politique_confidentialite_skinanalysia.pdf'}
      />
      <PopinPdf active={pdfPopinCGULaboratory} onClose={() => setPdfPopinCGULaboratory(false)} document={document} />

      <Breadcrumb step={1} />
      <div className={Styles.LiqaContainer}>
        {/* @ts-ignore TODO: See if it is possible to import the component differently so that it is recognized by TS*/}
        <hautai-liqa
          ref={liqaRef}
          license={process.env.REACT_APP_LIQA_LICENSE_KEY}
          // TODO Extract french texts to a JSON file
          messages={
            locale === ELocale.frFr
              ? `{
  "source_selection": {
    "title": "Choisissez l&apos;option de prise de photo",
    "camera_permissions_warning": {
      "title": "Accès à la caméra refusé",
      "instructions_reset": "Modifiez l&apos;autorisation d&apos;accès à la caméra du navigateur pour ce site ou chargez une photo depuis votre appareil",
      "instructions_reload": "Modifiez l&apos;autorisation d&apos;accès à la caméra du navigateur pour ce site ou chargez une photo depuis votre appareil"
    },
    "camera_video": "Prendre une photo",
    "upload_photo": "Charger depuis l&apos;appareil"
  },
  "face_live_video": {
    "face_displaced": "Placez votre visage dans le cadre",
    "face_placement_fixed": "Parfait !",
    "face_distance_far": "Approchez-vous",
    "face_distance_close": "Éloignez-vous",
    "face_distance_fixed": "Parfait !",
    "face_angle_deviation": "Tournez votre visage vers la caméra",
    "face_angle_fixed": "Parfait !",
    "lighting_insufficient": "Trouvez un endroit plus lumineux",
    "lighting_excessive": "Trouvez un endroit moins lumineux",
    "lighting_angle_deviation": "Tournez-vous face à la lumière",
    "lighting_fixed": "Parfait !",
    "capturing": "Regardez la caméra"
  },
  "preview": {
    "title": "Bien joué !",
    "text": "Voici votre photo",
    "retake": "Reprendre",
    "submit": "Envoyer"
  }
}
`
              : '{}'
          }
        />
      </div>
    </div>
  );
};

export default PictureMobile;
