import React, { useEffect, useState } from 'react';

import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import RecommendationCard from '@/components/RecommandationCard';
import SubRoutine from '@/components/SubRoutine';

import { TRoutine } from './interfaces';
import Styles from './styles.module.scss';

const Routine: React.FC<TRoutine> = ({ index, rIndex, routine, isCurrentStep, active, recommendationRoutines, onStepChange, onCardSelect }) => {
  const [activeCards, setActiveCards] = useState<number[]>(
    routine.proposed_products.reduce((acc, product, index) => {
      if (active && product.id === active.id) {
        acc.push(index);
      }
      return acc;
    }, [] as number[])
  );
  const [showSubRoutines, setShowSubRoutines] = useState(false);

  const onProductSelect = (id: number, name: string, isInitial: boolean, active: boolean, routineIndex: number, pIndex: number, image_url?: string) => {
    setActiveCards((prevActiveCards) => {
      if (active) {
        return [...prevActiveCards, pIndex];
      } else {
        return prevActiveCards.filter((index) => index !== pIndex);
      }
    });

    onCardSelect(id, active, routineIndex);
  };

  useEffect(() => {
    const activeIndices = routine.proposed_products.reduce((acc, product, index) => {
      if (active && product.id === active.id) {
        acc.push(index);
      }
      return acc;
    }, [] as number[]);
    setActiveCards(activeIndices);
  }, [active, routine.proposed_products]);

  return (
    <div key={'recommendationRoutine-' + rIndex} className={Styles['recommendationRoutine']}>
      <Inner
        className={Styles['recommendationRoutine__heading']}
        type={'full'}
        onClick={() => {
          onStepChange(rIndex);
        }}
      >
        <div className={Styles['recommendationRoutine__title']}>
          <span>{rIndex + 1}.</span>
          {routine.title}
        </div>
      </Inner>
      <div className={Styles['recommendationRoutine__body']}>
        <Inner type='full'>
          <div className={`${Styles['recommendationRoutine__slider']} ${routine.proposed_products.length === 1 && Styles['center']}`}>
            {routine?.proposed_products?.map((product: any, pIndex: number) => (
              <RecommendationCard
                key={'recommendationCard-' + pIndex}
                {...product}
                isActive={activeCards.includes(pIndex)}
                onSelect={(id, name, isInitial, active, i, image_url) => onProductSelect(id, name, isInitial, active, index, pIndex, image_url)}
              />
            ))}
          </div>
        </Inner>
      </div>
      <div className={Styles['recommendationRoutine__footer']}>
        <Inner type='full'>
          {routine.sub_routines.length > 0 ? (
            <p
              className={Styles['recommendationRoutine__sub-more']}
              onClick={() => {
                setShowSubRoutines(!showSubRoutines);
              }}
            >
              <LocaleText>page.recommendationStep.subRoutines</LocaleText>
            </p>
          ) : (
            ''
          )}
        </Inner>
        <div className={`${Styles['recommendationRoutine__subs']} ${showSubRoutines ? Styles['recommendationRoutine__subs--active'] : ''}`}>
          {routine.sub_routines
            .sort((a: any, b: any) => a.position - b.position)
            .map((sub_routine: any, rIndex: number) => (
              <SubRoutine
                key={'recommendationRoutine-' + index + '-sub-' + rIndex}
                index={index + rIndex + 1}
                rIndex={rIndex}
                parentIndex={index}
                routine={sub_routine}
                active={recommendationRoutines?.[index + rIndex + 1]}
                isCurrentStep={isCurrentStep}
                onStepChange={onStepChange}
                onCardSelect={onCardSelect}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

export default Routine;
