import { FunctionComponent, MouseEvent, useContext, useState } from 'react';
import { LocaleContext } from '@/providers/LocaleProvider';
import Button from '@/components/Button';
import Form, { EInputType, TForm } from '@/components/Form';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import Styles from './styles.module.scss';
// import { CampaignContext } from '@/providers/AuthProvider/campaignInfoProvider';
import ERoutes from '@/routers/AuthRouter/routes';
import { useNavigate } from 'react-router-dom';
import campaignCreate from '@/helpers/API/requests/campaignCreate';
import { useGetAnimatorData } from '@/hooks/useGetAnimatorData';

export type TAnimatorNewCampaign = {
  campaignName: string;
  salepointName: string;
  salepointZipcode: string;
  campaignDate: string;
  brands: number[];
};

const AnimatorNewCampaign: FunctionComponent = (brands) => {
  const router = useNavigate();
  const { getTranslation } = useContext(LocaleContext);

  const [selectedBrands, setSelectedBrands] = useState<number[]>([]);
  const [showError, setShowError] = useState<boolean>(false);
  //TODO put an error message
  // const { campaignError } = useContext(CampaignContext);

  const { brandsData, isLoading } = useGetAnimatorData();

  const handleClick = (evt: MouseEvent<HTMLInputElement>) => {
    const clickedButton = evt.target as HTMLInputElement;
    clickedButton.classList.toggle('selected-brand');
    const clickedBrandId = brandsData.find((brand) => brand.name === clickedButton.value)?.id;
    if (clickedBrandId) {
      const updatedBrands = [...selectedBrands];
      if (selectedBrands.includes(clickedBrandId)) {
        updatedBrands.splice(selectedBrands.indexOf(clickedBrandId), 1);
        setSelectedBrands(updatedBrands);
      } else {
        updatedBrands.push(clickedBrandId);
        setSelectedBrands(updatedBrands);
      }
    }
  };

  if (isLoading) {
    return <div>Chargement...</div>;
  }
  return (
    <Inner className={Styles['animatorNewCampaign__body']}>
      <div className={Styles['animatorNewCampaign__title']}>
        <LocaleText>page.animator.new.title</LocaleText>
      </div>
      <div className={Styles['animatorNewCampaign__subtitle']}>
        <LocaleText>page.animator.new.subtitle</LocaleText>
      </div>
      {showError ? (
        <p className={Styles['animatorNewCampaign__error']}>
          <LocaleText>page.animator.new.brandError</LocaleText>
        </p>
      ) : null}
      <Form
        className={Styles['animatorNewCampaign__form']}
        onSubmit={(form) => {
          setShowError(false);
          if (selectedBrands.length === 0) {
            setShowError(true);
            return;
          }
          const formData: TForm = { ...form, brands: selectedBrands };
          campaignCreate(formData as TAnimatorNewCampaign)
            .then(() => {
              router(ERoutes.Animator);
            })
            .catch((e) => {
              setShowError(true);
            });
        }}
      >
        <Form.Input name='campaignName' type={EInputType.TEXT} required placeholder={getTranslation('form.campaign.campaignName')} />
        <Form.Input name='salepointName' type={EInputType.TEXT} required placeholder={getTranslation('form.campaign.salepointName')} />
        <Form.Input name='salepointZipcode' type={EInputType.TEXT} required placeholder={getTranslation('form.campaign.salepointZipcode')} />
        <Form.Input name='campaignDate' type={EInputType.DATE} required placeholder={getTranslation('form.campaign.campaignDate')} />
        <div className={Styles.animatorNewCampaign__brandsSubtitle}>
          <LocaleText>page.animator.new.selectBrands</LocaleText>
        </div>
        <div className={Styles.animatorNewCampaign__brandContainer}>
          {brandsData?.map((brand) => (
            <input type='button' className={Styles.animatorNewCampaign__brandButton} key={brand.id} value={brand.name} onClick={handleClick} />
          ))}
        </div>

        <Button type='submit' className={Styles['animatorNewCampaign__submit']}>
          <LocaleText>page.animator.new.submit</LocaleText>
        </Button>
      </Form>
      <Button outline={true} onClick={() => router(ERoutes.Animator)}>
        <LocaleText>page.animator.new.cancel</LocaleText>
      </Button>
    </Inner>
  );
};

export default AnimatorNewCampaign;
