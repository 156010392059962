
import React from 'react';

import { ProcessContext } from '@/screens/Auth/ProcessAI';

import LocaleText from '../Intl/LocaleText';
import Popin from '../Popin';

import { TPopinDiagnosisProps } from './interfaces';
import Styles from './styles.module.scss';

const PopinDiagnosis : React.FC<TPopinDiagnosisProps> = ({
    title = "global.popinDiagnosis.title",
    active = false
}) => {
    const processContext = React.useContext(ProcessContext)

    return (
        <Popin active={active}>
            <p className={Styles['popinDiagnosis__title']}>
                <LocaleText>{title}</LocaleText>
            </p>
            <div className={Styles['popinDiagnosis__footer']}>
                <p className={Styles['popinDiagnosis__subtitle']}>
                    <i><LocaleText>global.popinDiagnosis.subtitle</LocaleText></i>
                </p>
                <p className={Styles['popinDiagnosis__backHome']}
                   onClick={() => {processContext.goToHome()}}
                >
                    <u><LocaleText>global.popinDiagnosis.backHome</LocaleText></u>
                </p>
            </div>
        </Popin>
    );
};

export default PopinDiagnosis;