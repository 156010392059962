import React, { useState } from 'react';

import { TPopinPdfProps } from './interfaces';
import Styles from './styles.module.scss';
import LocaleText from "@/components/Intl/LocaleText";
import Popin from '../Popin';
import { Page, Document } from 'react-pdf';

const PopinPdf: React.FC<TPopinPdfProps> = ({
    active = false,
    onClose,
    document,
}) => {
    const [numPagesPdf, setNumPagesPdf] = useState(null);
    const [pageNumberPdf, setPageNumberPdf] = useState(1);

    function onDocumentLoadSuccess(documentProxy?: any) {
        setNumPagesPdf(documentProxy._pdfInfo.numPages);
    }
    function nextPage() {
        if (numPagesPdf != null && pageNumberPdf < numPagesPdf) {
            setPageNumberPdf(pageNumberPdf + 1)
        }
    }
    function previousPage() {
        if (pageNumberPdf > 1) {
            setPageNumberPdf(pageNumberPdf - 1)
        }
    }

    return (
        <Popin showClose active={active} onClickUnderlay={() => null} onClose={onClose}>
            <div className={Styles['popinPdf__container']}>
                <div className={Styles['popinPdf__body']}>
                    <div className={Styles['popinPdf__embed']}>
                        <Document file={document} onLoadSuccess={onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumberPdf} renderTextLayer={false} renderAnnotationLayer={false} />
                        </Document>
                    </div>
                    <div className={Styles['popinPdf__footer']}>
                        <p>Page {pageNumberPdf} / {numPagesPdf}</p>
                        <button onClick={previousPage}><LocaleText>global.popinPdf.previous</LocaleText></button>
                        <button onClick={nextPage}><LocaleText>global.popinPdf.next</LocaleText></button>
                    </div>
                </div>
            </div>
        </Popin>
    );
};

export default PopinPdf;