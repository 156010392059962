export enum EValidation {
    /**
     * Required validation.
     */
    Required = 'required'
}

export type TValidationSection = {
    /**
     * Is the rule enabled ?
     */
    enabled: boolean;

    /**
     * Message to render is case of error.
     */
    message: string;
};

export type TValidation = {
    /**
     * Validation object's description.
     */
    [key in EValidation]: TValidationSection;
};