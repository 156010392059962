import FR from './fr.json';
import EN from './en.json';
import { ELocale, TLocaleTranslation, TTranslation } from '@/providers/LocaleProvider/interfaces';

const translations: TLocaleTranslation = {
    [ELocale.frFr]: FR as unknown as TTranslation,
    [ELocale.enGb]: EN as unknown as TTranslation,
    [ELocale.enUs]: EN as unknown as TTranslation,
};

export default translations;