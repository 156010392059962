import React from 'react';

import { TTitleProps } from './interfaces';
import Styles from './styles.module.scss';

const Title : React.FC<TTitleProps> = ({
    className = '',
    type,
    children,
}) => {
    switch (type) {
        case 1:
        return (
            <h1 className={`${Styles['title']} ${className ? className : ''}`}>
            {children}
          </h1>
        )
        case 2:
        return (
            <h2 className={`${Styles['title']} ${className ? className : ''}`}>
            {children}
          </h2>
        )
        case 3:
        return (
            <h3 className={`${Styles['title']} ${className ? className : ''}`}>
            {children}
          </h3>
        )
        case 4:
        return (
            <h3 className={`${Styles['title']} ${className ? className : ''}`}>
            {children}
          </h3>
        )
    };
    return <p className={`${Styles['title']} ${className ? className : ''}`}>{children}</p>
};

export default Title;