import { adjustColorBrightness } from '@/helpers/color';
import { useGenerateCampaign } from '@/hooks/useGenerateCampaign';
import React, { FC, createContext, useMemo } from 'react';

interface CampaignProviderProps {
  children: React.ReactNode;
}

interface CampaignContextType {
  primaryColor: string;
  secondaryColor: string;
  token: string;
  campaignLoading: boolean;
  isLaboratory: boolean;
  hideSensitiveSkin: boolean;
  availablePathologies: Array<string>;
  document: string;
  labName: string;
  campaignError: string | null;
}

export const CampaignContext = createContext<CampaignContextType>({
  primaryColor: '',
  secondaryColor: '',
  token: '',
  campaignLoading: false,
  isLaboratory: false,
  hideSensitiveSkin: false,
  availablePathologies: [],
  document: '',
  labName: '',
  campaignError: null,
});

export const CampaignProvider: FC<CampaignProviderProps> = ({ children }) => {
  const { campaignData, isLoading, token, campaignError } = useGenerateCampaign();

  const labInfos = useMemo(() => {
    if (campaignData) {
      const {
        laboratory: { hideSensitiveSkin, availablePathologies, document, name },
      } = campaignData;
      return { isLaboratory: true, hideSensitiveSkin, availablePathologies, document, name };
    }
    return { isLaboratory: false, hideSensitiveSkin: false, availablePathologies: [], document: '', name: '' };
  }, [campaignData]);

  const colors = useMemo(() => {
    if (campaignData) {
      const {
        laboratory: { color1, color2 },
      } = campaignData;
      return { primary: color1, secondary: color2 };
    }
    return { primary: '', secondary: '' };
  }, [campaignData]);

  React.useEffect(() => {
    if (colors.primary !== '' && colors.secondary !== '') {
      document.documentElement.style.setProperty('--primary-color', colors.primary);
      document.documentElement.style.setProperty('--primary-color-dark', adjustColorBrightness(colors.primary, -10));
      document.documentElement.style.setProperty('--secondary-color', colors.secondary);
    }
  }, [colors]);

  return (
    <CampaignContext.Provider
      value={{
        primaryColor: colors.primary,
        secondaryColor: colors.secondary,
        token,
        campaignLoading: isLoading,
        isLaboratory: labInfos.isLaboratory,
        hideSensitiveSkin: labInfos.hideSensitiveSkin,
        availablePathologies: labInfos.availablePathologies,
        document: labInfos.document,
        labName: labInfos.name,
        campaignError,
      }}
    >
      {children}
    </CampaignContext.Provider>
  );
};
