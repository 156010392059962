import React from 'react';
import IcoMoon from 'react-icomoon';

import { TIconProps } from './interfaces';

const iconSet = require("@/globals/icomoon/selection.json");

const Icon : React.FC<TIconProps> = ({
  className = '',
  icon
}) => (
    <IcoMoon iconSet={iconSet} icon={icon} className={className ?? ''} />
);

export default Icon;