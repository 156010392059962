import { FunctionComponent, useContext } from 'react';
import Header from '@/components/Header';
import LocaleText from '@/components/Intl/LocaleText';
import Button from '@/components/Button';
import Styles from './styles.module.scss';
import Inner from '@/components/Inner';
import { useNavigate } from 'react-router-dom';
import ERoutes from '@/routers/AuthRouter/routes';
import { useGetAnimatorData } from '@/hooks/useGetAnimatorData';
import { AuthContext } from '@/providers/AuthProvider';
import { EUserRole } from '@/providers/AuthProvider/interfaces';

const Animator: FunctionComponent = () => {
  const router = useNavigate();
  const { currentCampaignData, futureCampaignData, isLoading } = useGetAnimatorData();

  const auth = useContext(AuthContext);
  const { user } = auth;

  const isAnimator = user?.roles.includes(EUserRole.ANIMATOR);

  if (isLoading) {
    return <div>Chargement...</div>;
  }
  return (
    <>
      <Header hideDisconnect={false} />
      <Inner className={Styles['animator__body']}>
        <div className={Styles['animator__title']}>
          <LocaleText>page.animator.title</LocaleText>
        </div>
        {isAnimator && (
          <Button outline={true} className={Styles['animator__campaign-button']} onClick={() => router(ERoutes.AnimatorNewCampaign)}>
            <LocaleText>page.animator.new.title</LocaleText>
          </Button>
        )}
        <div className={Styles['animator__horizontalLine']}></div>
        <div className={Styles['animator__campaign-list']}>
          <div className={Styles['animator__section']}>
            <LocaleText>page.animator.current</LocaleText>
          </div>
          {currentCampaignData.length > 0 ? (
            currentCampaignData.map((campaign) => (
              <Button
                key={campaign.token}
                onClick={() => router(ERoutes.ProcessAI + '?campaign=' + campaign.token)}
                outline={true}
                className={Styles['animator__campaign-button']}
              >
                {campaign.name}
              </Button>
            ))
          ) : (
            <div className={Styles.animator__noCampaign}>
              <LocaleText>page.animator.none</LocaleText>
            </div>
          )}
          <div className={Styles['animator__section']}>
            <LocaleText>page.animator.future</LocaleText>
          </div>
          {futureCampaignData.length > 0 ? (
            futureCampaignData.map((campaign) => (
              <Button key={campaign.token} outline={true} className={`${Styles['animator__campaign-button']} ${Styles['animator__future-campaign']}`}>
                {campaign.name}
              </Button>
            ))
          ) : (
            <div className={Styles.animator__noCampaign}>
              <LocaleText>page.animator.none</LocaleText>
            </div>
          )}
        </div>
      </Inner>
    </>
  );
};

export default Animator;
