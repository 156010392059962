
import React from 'react';

import LocaleText from '../Intl/LocaleText';
import Popin from '../Popin';

import { TPopinErrorProps } from './interfaces';
import Styles from './styles.module.scss';

const PopinError : React.FC<TPopinErrorProps> = ({
    title,
    description,
    backHome,
    active = false,
    onClose,
}) => {
    return (
        <Popin active={active} onClickUnderlay={onClose} onClose={onClose}>
            <div className={Styles['popinError__container']}>
                <p className={Styles['popinError__title']}>
                    <LocaleText>{title}</LocaleText>
                </p>
                <div className={Styles['popinError__description']}>
                    <LocaleText isHtml>{description}</LocaleText>
                </div>
                <p className={Styles['popinError__backHome']} onClick={onClose}>
                    <LocaleText>{backHome}</LocaleText>
                </p>
            </div>
        </Popin>
    );
};

export default PopinError;