import API from '..';

import {
    TAuthentificate,
    TAuthResponse
} from './interfaces/auth';

const authentificate: TAuthentificate = (username, password) => 
    API.post<TAuthResponse>('/login_check', {
        username,
        password
    })
    .then(({ data }) => data)
;

export default authentificate;