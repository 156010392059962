import API from '..';

import {
    TCampaignListResponse,
    TCampaignList
} from './interfaces/campaignList';

const campaignList: TCampaignList = () =>
    API.get<TCampaignListResponse>('/campaign')
        .then(({ data }) => data)
;

export default campaignList;