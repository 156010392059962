import React from 'react';

import jwtDecode from 'jwt-decode';

import { AuthConsumer } from '@/providers/AuthProvider';
import { LocaleContext } from '@/providers/LocaleProvider';
import { TUser } from '@/providers/AuthProvider/interfaces';

import Button from '@/components/Button';
import Form, { EInputType } from '@/components/Form';
import Inner from '@/components/Inner';
import LocaleText from '@/components/Intl/LocaleText';
import Title from '@/components/Title';

import authentificate from '@/helpers/API/requests/auth';

import Styles from './styles.module.scss';
import { CampaignContext } from '@/providers/AuthProvider/campaignInfoProvider';

export type TLogin = {
  /**
   * User email field
   */
  email: string;

  /**
   * User password field
   */
  password: string;
};

const Login: React.FC = () => {
  const { getTranslation } = React.useContext(LocaleContext);

  const [showCredentialsError, setShowCredentialsError] = React.useState<boolean>(false);
  const [showOtherError, setShowOtherError] = React.useState<boolean>(false);
  const { campaignError } = React.useContext(CampaignContext);

  return (
    <AuthConsumer>
      {({ login }) => (
        <Inner>
          <Form
            className={Styles['login']}
            onSubmit={(form) => {
              setShowCredentialsError(false);
              setShowOtherError(false);
              const { email, password } = form as TLogin;

              authentificate(email.trim(), password)
                .then(({ token }) => {
                  const { username, roles } = jwtDecode<TUser>(token);

                  login(token, { username, roles });
                })
                .catch((e) => {
                  console.log({ e });
                  if (e.response?.data?.message === 'Identifiants invalides.') setShowCredentialsError(true);
                  else setShowOtherError(true);
                });
            }}
          >
            <Title className={Styles['login__title']}>
              <LocaleText>form.login.title</LocaleText>
            </Title>
            <p className={Styles['login__subtitle']}>
              <LocaleText>form.login.subtitle</LocaleText>
            </p>
            {showCredentialsError ? (
              <p className={Styles['login__error']}>
                {' '}
                <LocaleText>form.login.error_credentials</LocaleText>
              </p>
            ) : null}
            {showOtherError ? (
              <p className={Styles['login__error']}>
                {' '}
                <LocaleText>form.login.error_other</LocaleText>
              </p>
            ) : null}
            <p className={Styles['login__error']}>{campaignError ? <LocaleText>{'global.' + campaignError}</LocaleText> : ''}</p>
            <div className={Styles['login__form']}>
              <Form.Input name='email' type={EInputType.EMAIL} required placeholder={getTranslation('form.login.email')} />
              <Form.Input name='password' type={EInputType.PASSWORD} required placeholder={getTranslation('form.login.password')} />
            </div>
            <Button type='submit' className={Styles['login__submit']}>
              <LocaleText>form.login.submit</LocaleText>
            </Button>
          </Form>
        </Inner>
      )}
    </AuthConsumer>
  );
};

export default Login;
