import React from 'react';

import Form from '@/components/Form';

import { ECustomerSkinProblem } from '@/helpers/API/requests/interfaces/customer';

import Layout from './Layout';

import { TPersonalizationStepForm } from './interfaces';
import LocaleText from '@/components/Intl/LocaleText';

const HealthTarget: React.FC<TPersonalizationStepForm> = ({ formRef }) => (
    <Layout title='form.personalizationStep.healthTarget.title'>
    {Object.values(ECustomerSkinProblem).map(value => (
        <Form.RadioButton
            key={value}
            name='problem'
            value={value}
            onChanged={() => {
                formRef.current!.next();
            }}
        >
            <LocaleText>{'form.personalizationStep.radio.' + value}</LocaleText>
        </Form.RadioButton>
    ))}
    </Layout>
);

export default HealthTarget;