import jwt_decode from "jwt-decode";

export const getExpirationDate = (token: string, tokenExpirationField = 'exp', tokenTimestampShift = 1000): Date | undefined => {
    let expires = jwt_decode<any>(token)[tokenExpirationField] as Date | number | undefined;

    if (typeof expires === 'number') {
        expires = new Date(expires * tokenTimestampShift);
    }

    return expires;
}