import React from 'react';

import Styles from './styles.module.scss';
import { TPurchaseValidationStep } from './interfaces';
import { ProcessContext } from '..';
import Title from '@/components/Title';
import LocaleText from '@/components/Intl/LocaleText';
import Inner from '@/components/Inner';
import PurchaseCard from '@/components/PurchaseCard';
import Spinner from '@/components/Spinner';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { TCustomerAnswerResponse } from '@/helpers/API/requests/interfaces/customer';

const PurchaseValidationStep: React.FC<TPurchaseValidationStep> = ({ products, onSubmit }) => {
  const [validationSubmitted, setValidationSubmitted] = React.useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = React.useState<number[]>([]);

  const { cartValidation: { success: cartValidation } = { success: null }, recommendations, goToHome } = React.useContext(ProcessContext);
  const { routines } = recommendations as TCustomerAnswerResponse;

  const onProductSelect = (id: number, name: string, isInitial: boolean, active: boolean) => {
    let tempArray = selectedProducts;
    if (active) {
      tempArray.push(id);
      setSelectedProducts(tempArray);
    } else {
      tempArray = tempArray.filter((item) => item !== id);
    }
    setSelectedProducts(tempArray);
  };

  if (products) {
    const routinesFiltered = routines.filter((routine) => {
      return (
        routine.proposed_products.find(({ id }) => products.map((product) => product.id).includes(id)) !== undefined ||
        routine.sub_routines.filter((sub) => {
          return sub.proposed_products.find(({ id }) => products.map((product) => product.id).includes(id)) !== undefined;
        }).length > 0
      );
    });

    return (
      <div className={Styles['purchaseValidationStep']}>
        <Inner type='md' className={Styles['purchaseValidationStep__heading']}>
          <Title className={Styles['purchaseValidationStep__title']}>
            <LocaleText>page.purchaseValidationStep.title</LocaleText>
          </Title>
          <Title className={Styles['purchaseValidationStep__subtitle']}>
            {cartValidation ? (
              <LocaleText>page.purchaseValidationStep.subtitle</LocaleText>
            ) : (
              <LocaleText>page.purchaseValidationStep.empty.subtitle</LocaleText>
            )}
          </Title>
        </Inner>
        <div className={Styles['purchaseValidationStep__body']}>
          {routinesFiltered.map((routine, rIndex) => {
            const matchingProducts = [];
            const isProductSelected = (productId: number) => products.some((selectedProduct) => selectedProduct.id === productId);

            for (const product of routine.proposed_products) {
              if (isProductSelected(product.id)) {
                matchingProducts.push(product);
              }
            }

            for (const subRoutine of routine.sub_routines) {
              for (const product of subRoutine.proposed_products) {
                if (isProductSelected(product.id)) {
                  matchingProducts.push(product);
                }
              }
            }

            if (matchingProducts.length > 0) {
              return (
                <div key={routine.position} className={Styles.purchaseValidationStep__routine__container}>
                  <div className={Styles.purchaseValidationStep__routine__title}>
                    <span>{rIndex + 1}.</span>
                    {routine.title}
                  </div>
                  {matchingProducts.map((product) => (
                    <PurchaseCard
                      key={`recommendationCard-${product.id}`}
                      className={Styles.purchaseValidationStep__routine__card}
                      {...product}
                      onSelect={(id, name, isInitial, active) => onProductSelect(id, name, isInitial, active)}
                    />
                  ))}
                </div>
              );
            }

            return null;
          })}
        </div>
        <div className={Styles['purchaseValidationStep__footer']}>
          <Inner type='md' className={Styles['purchaseValidationStep__footer-inner']}>
            <p className={Styles['purchaseValidationStep__footer-selected']}>
              {products.length} <LocaleText plural={products.length !== 1}>page.purchaseValidationStep.selectedProduct</LocaleText>
            </p>
            {cartValidation ? (
              <Button
                disabled={validationSubmitted}
                className={Styles['purchaseValidationStep__button']}
                onClick={() => {
                  setValidationSubmitted(true);
                  onSubmit(selectedProducts);
                  setTimeout(() => goToHome(), 3000);
                }}
              >
                {validationSubmitted ? (
                  <>
                    <Icon icon='check' />
                    <LocaleText>page.purchaseValidationStep.sent</LocaleText>
                  </>
                ) : (
                  <LocaleText>page.purchaseValidationStep.submit</LocaleText>
                )}
              </Button>
            ) : (
              <Button className={Styles['purchaseValidationStep__button']} onClick={() => goToHome()}>
                <LocaleText>page.purchaseValidationStep.empty.submit</LocaleText>
              </Button>
            )}
          </Inner>
        </div>
      </div>
    );
  }

  return (
    <div className={Styles['purchaseValidationStep']}>
      <Spinner className={Styles['purchaseValidationStep__loading']} />
    </div>
  );
};

export default PurchaseValidationStep;
