import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { getLaboratoryInfos } from '@/helpers/API/requests/customer';
import { TLabInfosResponse } from '@/helpers/API/requests/interfaces/customer';

const useGenerateLab = () => {
  const [isLoadingLab, setIsLoadingLab] = useState(false);
  const [labPreviewData, setLabPreviewData] = useState<TLabInfosResponse | null>();
  const [labError, setLabError] = useState('');

  const location = useLocation();
  const labToken = new URLSearchParams(location.search).get('laboratory');

  useEffect(() => {
    if (!labToken) {
      setIsLoadingLab(false);
      return;
    }

    setIsLoadingLab(true);
    const fetchData = async () => {
      try {
        const data = await getLaboratoryInfos(labToken);
        setLabPreviewData(data);
        setIsLoadingLab(false);
      } catch (error) {
        setIsLoadingLab(false);
        setLabError(error as string);
      }
    };

    fetchData();
  }, [labToken]);

  return { labPreviewData, isLoadingLab, labError };
};

export default useGenerateLab;
