import React from 'react';

import useDidUpdate from '@/hooks/useDidUpdate';

import { FormContext } from '../provider';
import Styles from './styles.module.scss';

import validation from '@/helpers/validation';
import { EValidation, TValidation } from '@/helpers/validation/interfaces';

import { TSelectProps, TSelectValue } from './interfaces';
import { TFormOverrideCallback } from '../interfaces';
import { TInputValue } from '@/components/Form/Input/interfaces';

const Select: React.FC<TSelectProps> = ({ name, className = '', id, defaultValue, value, placeholder, onChange, required, multiple, validate, children }) => {
  const formContext = React.useContext(FormContext);

  const [_value, setValue] = React.useState(
    defaultValue ?? value ?? (formContext.datas[name] as TSelectValue) ?? multiple ? ([] as TInputValue[]) : ('' as TInputValue)
  );
  const [error, setError] = React.useState<string | null>(null);

  const _onChange = React.useCallback(
    (__value: TSelectValue) => {
      const changeValue = onChange?.(__value);
      const val = (changeValue === undefined ? __value : changeValue) as TInputValue;

      if (multiple) {
        setValue((value) => {
          if (Array.isArray(value)) {
            let newValArray: TInputValue[];
            if (value.includes(val)) {
              newValArray = value.filter((choice) => choice !== val);
            } else {
              newValArray = value;
              newValArray.push(val);
            }
            return newValArray;
          }
          return value;
        });
      } else {
        setValue(val);
      }
    },
    [onChange, multiple]
  );

  useDidUpdate(() => {
    _onChange(value ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  React.useEffect(() => {
    formContext.datas[name] = _value;
    return () => {
      delete formContext.datas[name];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, _value]);

  React.useEffect(() => {
    formContext.override[name] = _onChange as TFormOverrideCallback;
    return () => {
      delete formContext.override[name];
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_onChange, name]);

  React.useEffect(() => {
    const _validate: TValidation = JSON.parse(JSON.stringify(validate ?? {}));

    if (required) {
      _validate[EValidation.Required] = {
        enabled: true,
        message: _validate[EValidation.Required]?.message ?? 'this field is required !',
      };
    }

    const isNotEmpty = Object.keys(_validate).length > 0;

    if (isNotEmpty) {
      formContext.validate[name] = (__value) => {
        const _error = validation(_validate, __value);

        setError(_error);
        return _error === null;
      };
    }

    return () => {
      if (isNotEmpty) {
        delete formContext.validate[name];
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, required, validate]);

  return (
    <div className={`${Styles['select']} ${className ? className : ''}`}>
      <select
        id={id}
        name={name}
        required={required}
        onChange={({ target }) => _onChange(target.value)}
        value={_value as React.SelectHTMLAttributes<HTMLSelectElement>['value']}
        className={Styles['select__select']}
        multiple={multiple}
        size={multiple ? 3 : 0}
      >
        {placeholder ? <option value=''>{placeholder}</option> : ''}
        {children}
      </select>
      {error && <p>{error}</p>}
    </div>
  );
};

export default Select;
