import React from 'react';

import LocaleText from '../Intl/LocaleText';
import Popin from '../Popin';

import { TTOUPopinProps } from './interfaces';
import Styles from './styles.module.scss';

const TOUPopin : React.FC<TTOUPopinProps> = ({
    active = false,
    onClose
}) => {
    
return (
    <Popin active={active} showClose={true} onClose={onClose}>
        <div className={Styles['touPopin__wrapper']}>
            <div className={Styles['touPopin__title']}>
                <LocaleText isHtml={true}>global.touPopin.title</LocaleText>
            </div>
            <p className={Styles['touPopin__subtitle']}>
                <LocaleText>global.touPopin.subtitle</LocaleText>
            </p>
            <div className={Styles['touPopin__description']}>
                <LocaleText isHtml={true}>global.touPopin.description</LocaleText>
            </div>
        </div>
    </Popin>
)};

export default TOUPopin;