import React from 'react';

import AbandonPopin from '@/components/PopinAbandon';
import Button from '@/components/Button';
import Form, { EInputType } from '@/components/Form';
import LocaleText from '@/components/Intl/LocaleText';
import TOUPopin from '@/components/PopinTOU';

import { LocaleContext } from '@/providers/LocaleProvider';

import Layout from './Layout';
import Styles from './styles.module.scss';
import Spinner from '@/components/Spinner';

const Email: React.FC = () => {
    const { getTranslation } = React.useContext(LocaleContext);

    const [emailValid, setEmailValid] = React.useState(false);
    const [consentValid, setConsentValid] = React.useState(false);
    const [abandonPopin, setAbandonPopin] = React.useState(false);
    const [touPopin, setTouPopin] = React.useState(false);
    const [focus, setFocus] = React.useState<boolean>(false);
    const [sending, send] = React.useState<boolean>(false);

    React.useEffect(() => {
        const timeId = setTimeout(() => {
            setFocus(true)
        }, 500);

        return () => {
            clearTimeout(timeId);
        };
    }, []);

    return (
        <Layout title='form.personalizationStep.email.title'>
            <Form.Input
                focus={focus}
                type={EInputType.EMAIL}
                name='customer_email'
                placeholder={getTranslation('form.personalizationStep.email.placeholder')}
                required
                onChange={value => {
                    //const regex = new RegExp('[a-zA-Z1-9.-_*]+@[a-zA-Z1-9.-]+');
                    const regex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                    setEmailValid(regex.test(value.toString()) === true ? true : false)
                }}
                className={Styles.center}
            />
            <div className={Styles['personnalisationStep__description']}>
                <LocaleText isHtml={true}>form.personalizationStep.email.description</LocaleText>
            </div>
            <div className={Styles['personnalisationStep__footer']}>
                <div className={`${Styles['personnalisationStep__consent']} ${Styles.center}`}>
                    <label className={Styles['personnalisationStep__consent-label']}>
                        <Form.Checkbox
                            value={consentValid}
                            name='customer_email_consent'
                            required
                            onChange={(value) => {
                                setConsentValid(value ? true : false)
                            }}
                        />
                        <LocaleText>form.personalizationStep.email.consent_label</LocaleText>
                    </label>
                    <a target="_blank" href="cgu_skinanalysia.pdf" className={Styles['personnalisationStep__consent-link']}><LocaleText>form.personalizationStep.email.consent_link</LocaleText></a>
                </div>
                <div className={Styles['personnalisationStep__footerCTA']}>
                    <Button type='submit' disabled={!(consentValid && emailValid)} className={Styles.submitButton} onClick={() => {
                        if (!sending) {
                            send(true);
                        }
                    }}>
                        <LocaleText>form.personalizationStep.email.submit</LocaleText>
                        {sending ? <Spinner className={Styles['personnalisationStep__footerCTA__spinner']} size='small' /> : undefined}
                    </Button>
                    <div className={Styles['personnalisationStep__footerCTA-abandon']}>
                        <p
                            onClick={() => { setAbandonPopin(true) }}
                        >
                            <LocaleText>page.pictureStep.diagnosisStep.stop</LocaleText>
                        </p>
                    </div>
                </div>
            </div>
            <AbandonPopin
                active={abandonPopin}
                onContinue={() => { setAbandonPopin(false) }}
            />
            <TOUPopin
                active={touPopin}
                onClose={() => { setTouPopin(false) }}
            />

        </Layout>
    );
};

export default Email;
