import React from 'react';

import LocaleText from '@/components/Intl/LocaleText';
import Styles from './styles.module.scss';

import { TRecapDiagnosisCard } from './interfaces';
import ImagePolygon from '@/components/ImagePolygon';
import {LocaleContext} from "@/providers/LocaleProvider";

const getRatingSettings = function(score: number, scoreType: string) {
    return {
        color: `page.diagnosisStep.color.${scoreType}`,
        label: `page.diagnosisStep.score.${scoreType}`
    }
}

const RecapDiagnosisCard: React.FC<TRecapDiagnosisCard> = ({
    card,
    picture
}) => {
    const { getTranslation } = React.useContext(LocaleContext);

    return (
        <div 
            className={Styles['recapDiagnosisCard']} 
        >
            <div className={Styles['recapDiagnosisCard__heading']}>{card.name}</div>
            <div className={Styles['recapDiagnosisCard__visual']}>
                <ImagePolygon image={picture} polygons={[card]} className={Styles['recapDiagnosisCard__visual-image']} />
            </div>
            <div className={Styles['recapDiagnosisCard__footer']}>
                <div className={`${Styles['recapDiagnosisCard__rating']} ${Styles['recapDiagnosisCard__rating--' + getTranslation(getRatingSettings(card.score, card.score_type)?.color)]}`}>
                    <div className={Styles['recapDiagnosisCard__rating-percentage']}>
                        <p className={Styles['recapDiagnosisCard__rating-percentage-number']}>{card.score}</p>
                        
                        <svg viewBox="-1 -1 38 38" className={Styles['recapDiagnosisCard__rating-percentage-circle']}>
                            <path className={Styles['recapDiagnosisCard__rating-percentage-circle-bg']}
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path className={Styles['recapDiagnosisCard__rating-percentage-circle-fg']}
                                strokeDasharray={`${card.score ? (card.score > 5 ? card.score - 5 : 0) : 0}, 100`}
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                        </svg>
                    </div>
                    <p className={Styles['recapDiagnosisCard__rating-label']}>
                        {card.front_name/*<LocaleText>{getRatingSettings(card.score, card.score_type)?.label}</LocaleText>*/}
                    </p>
                </div>
            </div>
        </div>
    )
};

export default RecapDiagnosisCard;