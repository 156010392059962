
import React from 'react';

import { ProcessContext } from '@/screens/Auth/ProcessAI';

import Button from '../Button';
import LocaleText from '../Intl/LocaleText';
import Popin from '../Popin';

import { TAbandonPopinProps } from './interfaces';
import Styles from './styles.module.scss';

const AbandonPopin : React.FC<TAbandonPopinProps> = ({
    title = "global.abandonPopin.title",
    active = false,
    onContinue,
    onGiveUp,
}) => {
    const processContext = React.useContext(ProcessContext)

    return (
        <Popin active={active} onClickUnderlay={onContinue}>
            <p className={Styles['abandonPopin__title']}>
                <LocaleText>{title}</LocaleText>
            </p>
            <div className={Styles['abandonPopin__footer']}>
                <Button className={Styles['abandonPopin__cta']} outline={true} onClick={() => {
                    if (onGiveUp) {
                        onGiveUp();
                    }
                    else {
                        processContext.goToHome();
                    }
                }}>
                    <LocaleText>global.abandonPopin.abandon</LocaleText>
                </Button>
                <Button className={Styles['abandonPopin__cta']} onClick={onContinue}>
                    <LocaleText>global.abandonPopin.continue</LocaleText>
                </Button>
            </div>
        </Popin>
    );
};

export default AbandonPopin;