import React from 'react';
import Icon from '../Icon';

import Logo from '@/globals/images/Logo_SVG.svg';

import { AuthConsumer } from '@/providers/AuthProvider';
import { LocaleContext } from '@/providers/LocaleProvider';
import { ELocale } from '@/providers/LocaleProvider/interfaces';

import { THeaderProps } from './interfaces';
import Styles from './styles.module.scss';

const echoLang = function (lang: ELocale) {
  switch (lang) {
    case ELocale.enUs:
    case ELocale.enGb:
      return 'en';
    case ELocale.frFr:
    default:
      return 'fr';
  }
};

const Header: React.FC<THeaderProps> = ({ hideDisconnect, hideLanguage, laboratoryDataLogo = undefined }) => {
  const localeContext = React.useContext(LocaleContext);
  const [showLanguageDropdown, setShowLanguageDropdown] = React.useState(false);

  return (
    <AuthConsumer>
      {({ logout }) => (
        <div className={`${Styles['header']}`}>
          <div className={`${Styles['header__disconnect']}`} onClick={() => logout()}>
            {!hideDisconnect ? <Icon icon='power' className={Styles['header__disconnect-icon']} /> : ''}
          </div>

          <img className={`${Styles['header__logo']}`} src={laboratoryDataLogo || Logo} alt='logo du laboratoire' />

          {!hideLanguage ? (
            <div
              className={`${Styles['header__language']} ${showLanguageDropdown ? Styles['is-active'] : ''}`}
              onClick={() => {
                setShowLanguageDropdown(!showLanguageDropdown);
              }}
            >
              <div className={`${Styles['header__language-current']}`}>{echoLang(localeContext.locale)}</div>
              <div className={`${Styles['header__language-dropdown']}`}>
                {[ELocale.frFr, ELocale.enUs].map((_lang) => (
                  <div
                    key={_lang}
                    className={`${Styles['header__language-item']} ${localeContext.locale === _lang ? Styles['is-active'] : ''}`}
                    onClick={() => {
                      if (localeContext.locale !== _lang) {
                        localeContext.setLocale(_lang);
                      }
                    }}
                  >
                    {echoLang(_lang)}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            ''
          )}
        </div>
      )}
    </AuthConsumer>
  );
};

export default Header;
