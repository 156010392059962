import React, { useContext } from 'react';

import { TUser, IAuthProps, EAuthActionType, EAuthType, TAuthTypeBasicValues, TAuthTypeBTokenValues, TAuthRetriveUserCallback, TAuthProps } from './interfaces';
import reducer, { defaultState } from './reducer';

import API from '@/helpers/API';
import { CampaignContext } from './campaignInfoProvider';
import { LaboratoryContext } from './labInfoProvider';

export const AuthContext = React.createContext<IAuthProps>({
  user: null,

  login: (values, user, options) => {},

  logout: () => {},

  editUser: (user: TUser) => {},
});

AuthContext.displayName = 'Auth';

export const AuthConsumer = AuthContext.Consumer;

const AuthProvider: React.FC<TAuthProps> = ({
  onRetriveUserFromCookie,
  tokenExpirationField,
  tokenTimestampShift = 1000,
  renderLoading = null,
  refreshTokenOptions,
  children,
}) => {
  const [loading, setLoading] = React.useState(true);
  const [state, dispatch] = React.useReducer(reducer, defaultState);
  const { token: campaignToken, campaignLoading } = React.useContext(CampaignContext);
  const { token: labToken, labLoading } = useContext(LaboratoryContext);

  /**
   * Config Authentification context's callbacks
   */
  const authContext = React.useMemo<IAuthProps>(
    () => ({
      ...state,

      login: (values, user, { type = EAuthType.BToken, refreshToken } = {}) => {
        switch (type) {
          case EAuthType.Basic:
            const { username, password } = values as TAuthTypeBasicValues;

            API.setAuthBasic!(username, password);
            break;
          case EAuthType.BToken:
            API.setAuthToken!(values as TAuthTypeBTokenValues, tokenExpirationField, tokenTimestampShift);
            if (refreshToken) {
              API.setRefreshToken!(refreshToken, tokenExpirationField);
            }
            break;
        }
        dispatch({ type: EAuthActionType.Set, user });
      },

      logout: () => {
        API.forgetAuthToken!();
        dispatch({ type: EAuthActionType.Set, user: null });
      },

      editUser: (user: TUser) => {
        dispatch({ type: EAuthActionType.Edit, user });
      },
    }),
    [state, tokenExpirationField, tokenTimestampShift]
  );

  /**
   * Check if user exist before loading page.
   */

  React.useEffect(() => {
    // Try to get a token from a cookie, campaignToken or labToken
    const token = API.getTokenFromCookie!() || campaignToken || labToken;

    if (refreshTokenOptions) {
      if (!refreshTokenOptions.tokenExpirationField) {
        refreshTokenOptions.tokenExpirationField = tokenExpirationField;
      }

      API.initRefreshToken!(refreshTokenOptions);
    }

    if (token) {
      API.setAuthToken!(token, tokenExpirationField);
      onRetriveUserFromCookie(token)
        .then((user) => {
          dispatch({ type: EAuthActionType.Set, user });
        })
        .catch(() => {
          API.forgetAuth!();
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignToken, labToken]);

  /**
   * If token is valid, set loading to false once user is loaded.
   */
  React.useEffect(() => {
    if (loading === true && state.user !== null) {
      setLoading(false);
    }
  }, [loading, state.user]);

  return loading || campaignLoading || labLoading ? renderLoading : <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};

export { EAuthType };

export type { TAuthRetriveUserCallback };

export default AuthProvider;
