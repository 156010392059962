import Cookie from "./Cookie";

const g_cookieName = '__lastRefresh';
const g_refreshDurationInSeconds = 86400;

export const initRefresh = () => {
    Cookie.set(g_cookieName, Date.now().toString());
}

export const shouldRefresh = () => {
    let lastRefresh = Cookie.get(g_cookieName);
    let needToRefresh = false;

    if (!lastRefresh || (Date.now() - parseInt(lastRefresh!) >= (g_refreshDurationInSeconds * 1000))) {
        needToRefresh = true;
        initRefresh();
    }
    
    return needToRefresh;
}