import useGenerateLab from '@/hooks/useGenerateLab';
import { FC, createContext, useEffect, useMemo } from 'react';

interface LabProviderProps {
  children: React.ReactNode;
}

interface LabContextType {
  token: string;
  laboratory: {
    primaryColor: string;
    secondaryColor: string;
    title: string;
    subtitle: string;
    description: string;
    logo: string;
    image: string;
  };
  labLoading: boolean;
  labError: string | null;
}

const emptyLabData = {
  token: '',
  laboratory: {
    primaryColor: '',
    secondaryColor: '',
    title: '',
    subtitle: '',
    description: '',
    logo: '',
    image: '',
  },
};

export const LaboratoryContext = createContext<LabContextType>({
  token: emptyLabData.token,
  laboratory: emptyLabData.laboratory,
  labLoading: false,
  labError: null,
});

export const LaboratoryProvider: FC<LabProviderProps> = ({ children }) => {
  const { labPreviewData, isLoadingLab, labError } = useGenerateLab();

  const labInfos = useMemo(() => {
    if (labPreviewData) {
      const { token, laboratory } = labPreviewData;
      return { token, laboratory: { primaryColor: laboratory.color1, secondaryColor: laboratory.color2, ...laboratory } };
    }
    return emptyLabData;
  }, [labPreviewData]);

  useEffect(() => {
    if (labInfos.laboratory.primaryColor !== '' && labInfos.laboratory.secondaryColor !== '') {
      document.documentElement.style.setProperty('--primary-color', labInfos.laboratory.primaryColor);
      document.documentElement.style.setProperty('--secondary-color', labInfos.laboratory.secondaryColor);
    }
  }, [labInfos]);

  return (
    <LaboratoryContext.Provider
      value={{
        token: labInfos.token,
        laboratory: labInfos.laboratory,
        labLoading: isLoadingLab,
        labError,
      }}
    >
      {children}
    </LaboratoryContext.Provider>
  );
};
