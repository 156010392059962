import { TValidation } from "@/helpers/validation/interfaces";

export type TBaseInput = {
    /**
     * Input's name
     */
    name: string;

    /**
     * Input's CSS classes
     */
    className?: string;

    /**
     * Input's id. (Default: undefined)
     */
    id?: string;
 
    /**
     * Input's required ? (Default: false)
     */
    required?: boolean;
 
    /**
     * Validation rules.
     */
    validate?: TValidation;

    
};

export enum EInputType {
    /*
    ** Input is a text. 
    */
    TEXT = 'text',

    /*
    ** Input is an email. 
    */
    EMAIL = 'email',

    /*
    ** Input is a password. 
    */
    PASSWORD = 'password',

    /*
    ** Input is a phone number. 
    */
    PHONE = 'tel',
    
    /*
    ** Input is a number. 
    */
    NUMBER = 'number',

    /*
    ** Input is an hidden field. 
    */
    HIDDEN = 'hidden',

    /*
    ** Input is a date.
    */
    DATE = 'date',
}

export type TInputValue = string | number;

export type TInputProps = TBaseInput & {
    /**
     * Input's type (default: EInputType.TEXT)
     */
    type?: EInputType;

    /**
     * Input's default value. (Default: undefined)
     */
    defaultValue?: TInputValue;

    /**
     * Input's value. (Default: undefined)
     */
    value?: TInputValue;

    /**
     * Input's placeholder. (Default: undefined)
     */
    placeholder?: string;

    /**
     * Event trigger when input is about to change.
     * If you return a value, it will override the new value.
     * (Default: undefined)
     */
    onChange?: (value: TInputValue) => TInputValue | void;

    /**
     * Event trigger when input has changed.
     */
    onChanged?: (value: TInputValue) => void;

    /**
     * Input Autofocus
     */
     autoFocus?: boolean;

     /**
      * Input is focus
      */
      focus?: boolean;
}