import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { TNotFoundProps } from './interfaces';

const NotFound: React.FC<TNotFoundProps> = ({ redirect }) => {
    const location = useLocation();

    return <Navigate to={redirect} state={{ from: location }} />;
};

export default NotFound;