import { generateCampaign } from '@/helpers/API/requests/customer';
import { TGenerateCampaignResponse } from '@/helpers/API/requests/interfaces/customer';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const useGenerateCampaign = () => {
  const location = useLocation();
  const campaignToken = new URLSearchParams(location.search).get('campaign');
  const [campaignData, setCampaignData] = useState<TGenerateCampaignResponse | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [campaignError, setCampaignError] = useState<string | null>(null);
  const [token, setToken] = useState('');

  useEffect(() => {
    if (!campaignToken) {
      setIsLoading(false);
      return;
    }
    setIsLoading(true);

    generateCampaign(campaignToken)
      .then((data) => {
        setCampaignData(data);

        const { token } = data;

        setIsLoading(false);
        setToken(token);
      })
      .catch((data) => {
        setIsLoading(false);
        if (data.response.data.error) {
          setCampaignError(data.response.data.error);
        }
      });
  }, [campaignToken]);

  return { campaignData, isLoading, token, campaignError };
};
