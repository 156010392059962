import React from 'react';

import LoginForm from '@/forms/Login';
import Header from '@/components/Header';

const Login : React.FC = () => (
    <>
        <Header hideDisconnect hideLanguage />
        <LoginForm/>
    </>
);

export default Login;