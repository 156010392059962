import React from "react";
import Spinner from "../Spinner";

import drawPolygon from "./drawPolygon";

import { TImagePolygon } from './interfaces';
//import Styles from './styles.module.scss';

const ImagePolygon : React.FC<TImagePolygon> = ({ image, polygons, className = '' }) => {
    const [imagePolygon, setImagePolygon] = React.useState<string | null>(null);

    React.useEffect(() => {
        const canvas = document.createElement('canvas');
        const context = canvas.getContext("2d");

        if (context) {
            const imageBin = new Image();

            imageBin.onload = function() {
                canvas!.width = imageBin.naturalWidth;
                canvas!.height = imageBin.naturalHeight;

                context.drawImage(imageBin, 0, 0);

                drawPolygon(context, polygons);

                setImagePolygon(canvas.toDataURL('image/jpeg'))
            };
            imageBin.src = image;
        }
    }, [image, polygons]);

    return imagePolygon ? (
        <img className={className ? className : ''} src={imagePolygon || undefined} alt='selfie' />
    ) : (
        <Spinner className="spinner" />
    );
};

export default ImagePolygon;