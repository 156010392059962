import { FunctionComponent, useContext, useState } from 'react';

import { LocaleContext } from '@/providers/LocaleProvider';
import { TCodeValidationStep } from './interfaces';

import { ProcessContext } from '..';

import Title from '@/components/Title';
import LocaleText from '@/components/Intl/LocaleText';
import Inner from '@/components/Inner';
import Button from '@/components/Button';
import Form, { EInputType } from '@/components/Form';

import Styles from './styles.module.scss';

const CodeValidationStep: FunctionComponent<TCodeValidationStep> = ({ cartId, onSubmit }) => {
  const { getTranslation } = useContext(LocaleContext);

  const [operatorCode, setOperatorCode] = useState<number>();

  const { goToHome } = useContext(ProcessContext);

  return (
    <div className={Styles.codeValidationStep}>
      <Inner type='md' className={Styles.codeValidationStep__heading}>
        <Title className={Styles.codeValidationStep__title}>
          <LocaleText>page.codeValidationStep.title</LocaleText>
        </Title>
        <Title className={Styles.codeValidationStep__subtitle}>
          <LocaleText>page.codeValidationStep.subtitle</LocaleText>
        </Title>
      </Inner>

      <Form.Input
        type={EInputType.NUMBER}
        name='customer_email'
        placeholder={getTranslation('page.codeValidationStep.placeholder')}
        required
        value={operatorCode}
        onChange={(value) => setOperatorCode(value as number)}
        className={Styles.codeValidationStep__input}
      />

      <div className={Styles.codeValidationStep__footer}>
        <Button
          className={Styles.codeValidationStep__button}
          onClick={() => {
            onSubmit(cartId, operatorCode as number);
            setTimeout(() => goToHome(), 3000);
          }}
        >
          <LocaleText>page.codeValidationStep.submit</LocaleText>
        </Button>
      </div>
    </div>
  );
};

export default CodeValidationStep;
