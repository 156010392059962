import React from 'react';

import { TFormProviderValue } from './interfaces';

export const FormContext = React.createContext<TFormProviderValue>({
    datas: {},
    override: {},
    validate: {}
});

FormContext.displayName = 'Form';

export const FormConsumer = FormContext.Consumer;