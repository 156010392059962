import React from 'react';
import { Routes, Route } from "react-router-dom";

import ERoutes from './routes';

/**
 * Screens
 */
import Login from '@/screens/NotAuth/Login';

import NotFound from '@/screens/NotFound';
import Pharma from "@/screens/NotAuth/Pharma";

const NotAuthRouter: React.FC = () => (
    <Routes>
        <Route path={ERoutes.Login} element={<Login />} />
        <Route path={ERoutes.Mobile} element={<Pharma />} />
        <Route path='*' element={<NotFound redirect={ERoutes.Login} />} />
    </Routes>
);

export default NotAuthRouter;