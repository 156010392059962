import React from 'react';

import Form from '@/components/Form';

import Layout from './Layout';

import { TPersonalizationStepForm } from './interfaces';
import LocaleText from '@/components/Intl/LocaleText';

const Sensibility: React.FC<TPersonalizationStepForm> = ({ formRef }) => (
    <Layout title='form.personalizationStep.sensibility.title'>
    {[
        true,
        false
    ].map((value, i) => (
        <Form.RadioButton
            key={i}
            name='sensitive_skin'
            value={value}
            onChanged={() => {
                formRef.current!.next();
            }}
        >
            <LocaleText>{'form.personalizationStep.radio.' + value}</LocaleText>
        </Form.RadioButton>
    ))}
    </Layout>
);

export default Sensibility;