enum ERoutes {

    /**
     * Default home page route.
     */
    Home = '/',
    
    /**
     * Login page, use by API to redirect in case of token expiration.
     */
    Login = Home,

    Mobile = "/mobile"
}

export default ERoutes;