import React from 'react';

import { TRoundProgressProps } from './interfaces';

import Styles from './styles.module.scss';

const RoundProgress: React.FC<TRoundProgressProps> = ({
    progress,
    children,
    reverse = false,
    backgroundColor,
    foregroundColor,
    className,
    transitionDelay = 1000
}) => {
    const progressRound = React.useMemo(() => Math.round(Math.min(Math.max(progress, 0), 100)), [progress]);

    return (
        <div className={`${Styles['roundProgress']}${className ? ` ${className}` : ''}`}>
            <svg viewBox="-1 -1 38 38" className={`${Styles['roundProgress-circle']}${reverse ? ` ${Styles['roundProgress-circle--reverse']}` : ''}`}>
                <path className={Styles['roundProgress-circle-bg']} style={{ stroke: backgroundColor }}
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path className={Styles['roundProgress-circle-fg']} style={{ stroke: foregroundColor, transition: `${transitionDelay}ms linear` }}
                    strokeDasharray={`${progressRound}, 100`}
                    d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                />
            </svg>
            <p className={Styles['roundProgress-number']}>{children ?? progressRound}</p>
        </div>
    );
};

export default RoundProgress;