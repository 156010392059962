import { FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';
import PictureMobile from '@/components/PictureMobile';
import PictureNonMobile from '@/components/PictureNonMobile';
import { TPictureStep } from './interfaces';

const PictureStep: FunctionComponent<TPictureStep> = ({ onSubmit, onError }) => {
  return isMobile ? <PictureMobile onSubmit={onSubmit} onError={onError} /> : <PictureNonMobile onSubmit={onSubmit} onError={onError} />;
};

export default PictureStep;
