import React from 'react';

import 'slick-carousel/slick/slick.css';

import Icon from '@/components/Icon';

import Styles from './styles.module.scss';
import { TPurchaseCard } from './interfaces';
import LocaleText from '@/components/Intl/LocaleText';

const PurchaseCard: React.FC<TPurchaseCard> = ({ id, name, description, brand, className, isVisible = true, image_url, onSelect }) => {
  const [initialSelect, setInitialSelect] = React.useState(true);
  const [isActive, setIsActive] = React.useState(false);

  return (
    <div className={`${Styles['purchaseCardContainer']}${className ? ` ${className}` : ''}`} data-id={id}>
      <div
        className={`${Styles['purchaseCard']}${isActive ? ` ${Styles['purchaseCard--active']}` : ''}${isVisible ? ` ${Styles['purchaseCard--visible']}` : ''}`}
      >
        <div className={Styles['purchaseCard__body']}>
          <div className={Styles['purchaseCard__visual']}>
            <img src={image_url} className={Styles['purchaseCard__visual-image']} alt='card_visual' />
          </div>
          <div className={Styles['purchaseCard__content']}>
            <p className={Styles['purchaseCard__title']}>{name}</p>
            <p className={Styles['purchaseCard__brand']}>{brand?.name}</p>
            <p dangerouslySetInnerHTML={{ __html: description }} className={Styles['purchaseCard__description']}></p>
          </div>
        </div>
        <div
          className={Styles['purchaseCard__footer']}
          onClick={() => {
            onSelect(id, name, initialSelect, !isActive);
            setInitialSelect(false);
            setIsActive(!isActive);
          }}
        >
          {isActive ? (
            <>
              <p className={Styles['purchaseCard__footer-active']}>
                <LocaleText>page.purchaseValidationStep.footer.active </LocaleText>
              </p>
              <Icon icon='cross' />
            </>
          ) : (
            <p className={Styles['purchaseCard__footer-default']}>
              <LocaleText>page.purchaseValidationStep.footer.default </LocaleText>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default PurchaseCard;
