import React from 'react';

import { TButtonProps } from './interfaces';
import Styles from './styles.module.scss';

const Button : React.FC<TButtonProps> = ({
  id = undefined,
  className = '',
  backgroundColor,
  disabled = false,
  outline = false,
  white = false,
  fullwidth = false,
  size = 'large',
  type = 'button',
  onClick,
  children,
}) => (
  <button
        disabled={disabled}
        id={id}
        type={type}
        className={`${Styles['button']} ${outline ? Styles['button--outline'] : ''} ${white ? Styles['button--white'] : ''} ${fullwidth ? Styles['button--fullwidth'] : ''} ${Styles[`button--${size}`]} ${className ? className : ''}`}
        style={{ backgroundColor }}
        onClick={onClick}
    >
        {children}
    </button>
);

export default Button;