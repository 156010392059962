import { ELocaleActionType, TLocaleReducer } from './interfaces';

const reducer: TLocaleReducer = (prevState, { type, locale }) => {
    switch (type) {
        case ELocaleActionType.SetRemote:
            return {
                ...prevState,
                nextLocale: locale!,
            };
        
        case ELocaleActionType.SetLocal:
            return {
                ...prevState,
                locale: locale!,
                nextLocale: locale!,
            };

        case ELocaleActionType.UpdateRemote:
            return {
                ...prevState,
                locale: prevState.nextLocale,
                loading: false,
            };

        default:
            return prevState;
    }
};

export default reducer;
