import React from 'react';

import Checkbox from './Checkbox';
import Input, { EInputType } from './Input';
import RadioButton from './RadioButton';
import Select from './Select';

import { TInputValue } from './Input/interfaces';

import { FormContext } from './provider';
import { TFormProps, TForm, TFormProviderValue, IForm, TFormRef, TFormValue, TFormOverrideCallback, TFormOverrideCallbacks } from './interfaces';

export {
    EInputType
};

export type {
    TForm,
    TFormRef
};

const Form = React.forwardRef<TFormRef, TFormProps>(({
    children,
    id,
    initialValues,
    className = '',
    onSubmit
}, ref) => {
    const formRef = React.useRef<HTMLFormElement>(null);
    const buttonRef = React.useRef<HTMLButtonElement>(null);
    const form = React.useRef<TFormProviderValue>({
        datas: JSON.parse(JSON.stringify(initialValues ?? {})),
        override: {},
        validate: {}
    });

    const setFieldValue = React.useCallback((fieldName: string, data: TFormValue) => {
        if (form.current!.override[fieldName]) {
            if (typeof form.current!.override[fieldName] === 'function') {
                (form.current!.override[fieldName] as TFormOverrideCallback)(data);
            }
            else {
                for (const itemId in (form.current!.override[fieldName] as TFormOverrideCallbacks)) {
                    (form.current!.override[fieldName] as TFormOverrideCallbacks)[itemId](data);
                }
            }
        }
    }, []);

    React.useImperativeHandle(ref, () => ({
        next: () => {
            if (formRef.current) {
                if (formRef.current.requestSubmit !== undefined) {
                    formRef.current.requestSubmit();
                }
                else {
                    buttonRef.current!.click();
                }
            }
        },

        getFieldValues: () => form.current!.datas,

        getFieldValue: (fieldName: string) => form.current!.datas[fieldName],

        setFieldValues: (form: TForm) => {
            for (const field in form) {
                setFieldValue(field, form[field] as TInputValue);
            }
        },

        setFieldValue,
    } as TFormRef));

    return (
        <form
            ref={formRef}
            id={id}
            className={className}
            onSubmit={(ev) => {
                ev.preventDefault();

                let canSubmit = true;

                for (const field in form.current.validate) {
                    canSubmit = form.current.validate[field](form.current.datas[field]) && canSubmit;
                }

                if (canSubmit) {
                    onSubmit?.(form.current.datas);
                }
            }}
        >
            <FormContext.Provider value={form.current}>
                {children}
                <button style={{ display: 'none' }} ref={buttonRef} type='submit' />
            </FormContext.Provider>
        </form>
    );
});

(Form as IForm).Checkbox = Checkbox;
(Form as IForm).Input = Input;
(Form as IForm).RadioButton = RadioButton;
(Form as IForm).Select = Select;

export default Form as IForm;