import React from 'react';
import Header from "@/components/Header";
import AnimatorNewCampaignForm from "@/forms/AnimatorNewCampaign";

const AnimatorNewCampaign: React.FC = () => (
    <>
        <Header hideDisconnect={false} />
        <AnimatorNewCampaignForm/>
    </>
);

export default AnimatorNewCampaign;
