import API from '..';

import {
  TCustomerSelfie,
  TCustomerAnswer,
  TCustomerCartValidation,
  TCustomerSendRecap,
  TCustomerAnswerResponse,
  TCustomerSelfieResponse,
  TCustomerCartValidationResponse,
  TGenerateCampaignResponse,
  TPurchasedProducts,
  TPurchasedProductsResponse,
  TLabInfosResponse,
  TCodeValidation,
  TCodeValidationResponse,
} from './interfaces/customer';

export const customerSelfie: TCustomerSelfie = (processType, body, locale) =>
  API.post<TCustomerSelfieResponse>(`/${processType}/customer-answer/selfie?locale=${locale}`, body).then(({ data }) => data);

export const customerAnswer: TCustomerAnswer = (processType, body, locale) =>
  API.post<TCustomerAnswerResponse>(`/${processType}/customer-answer?locale=${locale}`, body).then(({ data }) => data);

export const customerCartValidation: TCustomerCartValidation = (processType, id, body) =>
  API.post<TCustomerCartValidationResponse>(`/${processType}/cart/${id}/customer-cart-validation`, body).then(({ data }) => data);

export const customerSendRecap: TCustomerSendRecap = (processType, body, locale) =>
  API.post<TCustomerCartValidationResponse>(`/${processType}/send-recap?locale=${locale}`, body).then(({ data }) => data);

export const generateCampaign: (campaignToken: string) => Promise<TGenerateCampaignResponse> = (campaignToken) =>
  API.post<TGenerateCampaignResponse>('/campaign/token/generate', { campaign: campaignToken }).then(({ data }) => data);

export const customerPurchasedProducts: TPurchasedProducts = (id, body) =>
  API.post<TPurchasedProductsResponse>(`/campaign/cart/${id}/purchase-validation`, body).then(({ data }) => data);

export const getLaboratoryInfos: (labToken: string) => Promise<TLabInfosResponse> = (labToken) =>
  API.get<TLabInfosResponse>(`/laboratory/infos?token=${labToken}`).then(({ data }) => data);

export const sendOperatorCode: TCodeValidation = (cartId, operatorCode) =>
  API.post<TCodeValidationResponse>(`/pharmacy/cart/${cartId}/operator-code`, { operatorCode: operatorCode }).then(({ data }) => data);
