import { TFormValue } from "@/components/Form/interfaces";

import { EValidation, TValidation } from "./interfaces";

const validation = (validateSections: TValidation, value: TFormValue): string | null => {
    for (const section in validateSections) {
        const validateSection = validateSections[section as EValidation];

        if (validateSection.enabled) {
            switch (section) {
                case EValidation.Required:
                    if (!value) {
                        return validateSection.message;
                    }
                    break;
            }
        }
    }

    return null;
}

export default validation;