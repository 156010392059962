import React from 'react';

import useDidUpdate from '@/hooks/useDidUpdate';

import { FormContext } from '../provider';
import Styles from './styles.module.scss';

import validation from '@/helpers/validation';
import { EValidation, TValidation } from '@/helpers/validation/interfaces';

import { TFormOverrideCallback } from '../interfaces';

import { EInputType, TInputProps, TInputValue } from './interfaces';

export {
    EInputType
};

const Input: React.FC<TInputProps> = ({
    name,
    className = '',
    type = EInputType.TEXT,
    id,
    defaultValue,
    value,
    placeholder,
    onChange,
    onChanged,
    required,
    validate,
    autoFocus,
    focus
}) => {
    const inputRef = React.useRef<any>(null)

    const formContext = React.useContext(FormContext);

    const [_value, setValue] = React.useState(defaultValue ?? value ?? formContext.datas[name] as TInputValue | undefined ?? '');
    const [error, setError] = React.useState<string | null>(null);

    const _onChange = React.useCallback((__value: TInputValue) => {
        const changeValue = onChange?.(__value);

        setValue(changeValue === undefined ? __value : changeValue);
    }, [onChange]);

    useDidUpdate(() => {
        _onChange(value ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    React.useEffect(() => {
        formContext.datas[name] = _value;
        return () => {
            delete formContext.datas[name];
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, _value]);

    React.useEffect(() => () => {
        delete formContext.datas[name];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    , [name]);

    React.useEffect(() => {
        formContext.override[name] = _onChange as TFormOverrideCallback;
        return () => {
            delete formContext.override[name];
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_onChange, name]);

    React.useEffect(() => {
        const _validate: TValidation =  JSON.parse(JSON.stringify(validate ?? {}));

        if (required) {
            _validate[EValidation.Required] = {
                enabled: true,
                message: _validate[EValidation.Required]?.message ?? 'this field is required !'
            }
        }

        const isNotEmpty = Object.keys(_validate).length > 0;

        if (isNotEmpty) {
            formContext.validate[name] = (__value) => {
                const _error = validation(_validate, __value);

                setError(_error);
                return _error === null;
            };
        }

        return () => {
            if (isNotEmpty) {
                delete formContext.validate[name];
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, required, validate]);

    useDidUpdate(() => {
        onChanged?.(_value);
    }, [_value]);

    React.useEffect(() => {
        if (focus && inputRef?.current) {
            inputRef?.current.focus()
        }
    }, [focus])

    return (
        <div className={`${Styles['input']} ${className ? className : ''}`}>
            <input
                ref={inputRef}
                autoFocus={autoFocus}
                className={`${Styles['input__input']}`}
                id={id}
                name={name}
                type={type}
                value={_value}
                placeholder={placeholder}
                required={required}
                onChange={({ target }) => _onChange(target.value)}
            />
            {error && <p>{error}</p>}
        </div> 
    );
}

export default Input;