import React from 'react';
import ErrorPage from './ErrorPage';

import { TErrorBoundaryState, TErrorBoundaryProps } from './interfaces';

class ErrorBoundary extends React.PureComponent<TErrorBoundaryState, TErrorBoundaryProps> {
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      errorInfo: null
    };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error: error,
      errorInfo: errorInfo
    });
  }

  render() {
    const {
      error,
      errorInfo
    } = this.state;

    const {
      children
    } = this.props;

    return (error === null && errorInfo === null) ? 
      children
      :
      <ErrorPage error={error} errorInfo={errorInfo} clearError={() => this.setState({ error: null, errorInfo: null })} />;
  }
};

export default ErrorBoundary;