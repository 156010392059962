import React from 'react';

import Form from '@/components/Form';

import { ECustomerPathology } from '@/helpers/API/requests/interfaces/customer';

import Layout from './Layout';

import { TPersonalizationStepForm } from './interfaces';
import LocaleText from '@/components/Intl/LocaleText';

import { CampaignContext } from '@/providers/AuthProvider/campaignInfoProvider';


const Pathology: React.FC<TPersonalizationStepForm> = ({ formRef }) => {
    
    const { isLaboratory, availablePathologies } = React.useContext(CampaignContext);
    return (
        <Layout title='form.personalizationStep.pathology.title'>
            {Object.values(ECustomerPathology).map(value => {
                if(isLaboratory && value != 'none' && !availablePathologies.includes(value)) {
                    return;
                }
                return (
                    <Form.RadioButton
                        key={value}
                        name='pathology'
                        value={value}
                        onChanged={() => {
                            formRef.current!.next();
                        }}
                    >
                        <LocaleText>{'form.personalizationStep.radio.' + value}</LocaleText>
                    </Form.RadioButton>
                );
            })}
        </Layout>
    )
};

export default Pathology;