import React from 'react';

import Icon from '../Icon';

import { TPopinProps } from './interfaces';
import Styles from './styles.module.scss';

const Popin : React.FC<TPopinProps> = ({
    active = false,
    showClose = false,
    onClickUnderlay,
    onClose,
    children,
}) => (
    <div className={`${Styles['popin__container']} ${active ? Styles['is-active'] : ''}`}>
        <div className={Styles['popin__underlay']} onClick={onClickUnderlay}></div>
        <div className={Styles['popin']}>
            {showClose ? (
                <div className={Styles['popin__close']} onClick={onClose}><Icon icon="cross"/></div>
            ) : ''}
            {children}
        </div>
    </div>
);

export default Popin;