enum ERoutes {
    
    /**
     * Default home page route.
     */
    Login = '/',
    
    /**
     * Process IA form steps page.
     */
    ProcessAI = '/',

    /**
     * Animator page
     */
    Animator = '/animator',

    /**
     * Animator page to create a new campaign
     */
    AnimatorNewCampaign = '/animator/new'
}

export default ERoutes;