import API from '..';

import {
    TCampaignCreateResponse,
    TCampaignCreate
} from './interfaces/campaignCreate';

const campaignCreate: TCampaignCreate = (form) =>
    API.post<TCampaignCreateResponse>('/campaign/create', form)
    .then(({ data }) => data)
;

export default campaignCreate;